import { Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Title from "../../../components/pages/Title";
import LinkTitle from "../../../components/pages/LinkTitle";
import CodeBlock from "../../../components/pages/CodeBlock";
import TextList from "../../../components/pages/TextList";
import Codes from '../../../constants/Codes';
import Paragraph from "../../../styles/components/Paragraph";

const BasicUsages = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.smartContact.usingMainnet.basicUsages' });
    const codes = Codes.SmartContract.UsingMainnet.BasicUsages;
    const developersUsingMainnetURL = `/smart-contract-guides/smart-contract-using-mainnet`;
    
    return (
        <Fragment>
            <Title text={ t("Set up the environment for development on the mainnet") } />

            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#install-eosjs" 
                    text={ t("1. Install eos.js") }
                />                
                <LinkTitle 
                    variant="h3" link="#install-eosjs-npm" 
                    text={ t("npm") }
                />
                <CodeBlock language={codes['install-eosjs-npm'].type}>
                    {codes['install-eosjs-npm'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#install-eosjs-yarn" 
                    text={ t("yarn") }
                />
                <CodeBlock language={codes['install-eosjs-yarn'].type}>
                    {codes['install-eosjs-yarn'].contents}
                </CodeBlock>


                <LinkTitle 
                    variant="h2" link="#basic-usage-eosjs" 
                    text={ t("2. Default eos.js setting") }
                />
                <Paragraph>
                    { t("The eosjs package provides two objects") }
                </Paragraph>
                <LinkTitle 
                    variant="h3" link="#basic-usage-eosjs-commonjs" 
                    text={ t("CommonJs") }
                />
                <Paragraph>
                    { t("Follow the code below to import eosjs using commonjs syntax") }
                </Paragraph>
                <CodeBlock language={codes['basic-usage-eosjs-commonjs'].type}>
                    {codes['basic-usage-eosjs-commonjs'].contents}
                </CodeBlock>
                
                <LinkTitle 
                    variant="h3" link="#basic-usage-eosjs-es-modules" 
                    text={ t("ES Modules") }
                />
                <Paragraph>
                    { t("Follow the code below to import eosjs using ES module syntax") }
                </Paragraph>
                <CodeBlock language={codes['basic-usage-eosjs-es-modules'].type}>
                    {codes['basic-usage-eosjs-es-modules'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h2" link="#create-private-key-for-account" 
                    text={ t("3. Generate an account private key") }
                />
                <LinkTitle 
                    variant="h3" link="#install-eosjs-ecc" 
                    text={ t("Install eosjs-ecc") }
                />
                <Paragraph>
                    { t("Eosjs-ecc is an encryption library") }
                </Paragraph>
                <LinkTitle 
                    variant="h4" link="#install-eosjs-ecc-npm" 
                    text={ t("npm") }
                />
                <CodeBlock language={codes['install-eosjs-ecc-npm'].type}>
                    {codes['install-eosjs-ecc-npm'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h4" link="#install-eosjs-ecc-yarn" 
                    text={ t("yarn") }
                />
                <CodeBlock language={codes['install-eosjs-ecc-yarn'].type}>
                    {codes['install-eosjs-ecc-yarn'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#create-private-key" 
                    text={ t("Generate a private key") }
                />
                <Paragraph>
                    { t("Follow the code below to import eosjs using commonjs syntax") }
                </Paragraph>
                <CodeBlock language={codes['basic-usage-eosjs-ecc-commonjs'].type}>
                    {codes['basic-usage-eosjs-ecc-commonjs'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("Follow the code below to import eosjs using ES module syntax") }
                </Paragraph>
                <CodeBlock language={codes['basic-usage-eosjs-ecc-es-modules'].type}>
                    {codes['basic-usage-eosjs-ecc-es-modules'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("You can generate a private key and a corresponding public key using the following code") }
                </Paragraph>
                <CodeBlock language={codes['create-private-key'].type}>
                    {codes['create-private-key'].contents}
                </CodeBlock>
                
                <LinkTitle 
                    variant="h2" link="#create-account-for-contract" 
                    text={ t("4. Create a contract account") }
                />
                <Paragraph>
                    { t("You must submit a transaction to your eosio account") }
                </Paragraph>
                <LinkTitle 
                    variant="h3" link="#newaccount" 
                    text={ t("newaccount") }
                />
                <Paragraph>
                    { t("The first action is the newaccount action") }
                </Paragraph>
                <TextList
                    type="decimal"
                    list={[
                        <Fragment>
                            { t("The owner must proceed with a new account-1") }
                            <Paragraph component="span" color="main.light" sx={{ textDecorationLine: 'underline' }}>
                                { t("The owner must proceed with a new account-link") }
                            </Paragraph>
                            { t("The owner must proceed with a new account-2") }
                        </Fragment>,
                        <Fragment>
                            { t("YOUR_PUBLIC_KEY-1") }
                            <Link component={HashLink} to="#create-private-key">
                                { t("YOUR_PUBLIC_KEY-link") }
                            </Link>
                            { t("YOUR_PUBLIC_KEY-2") }
                        </Fragment>,
                        {
                            color: 'red',
                            contents: t("The new account to be created must be a new account that does not exist")
                        }
                    ]}
                />
                <CodeBlock language={codes['newaccount'].type}>
                    {codes['newaccount'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#buyrambytes" 
                    text={ t("buyrambytes") }
                />
                <Paragraph>
                    { t("The second action is the buyrambytes action") }
                </Paragraph>
                <CodeBlock language={codes['buyrambytes'].type}>
                    {codes['buyrambytes'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#delegatebw" 
                    text={ t("delegatebw") }
                />
                <Paragraph>
                    { t("The third action is the delegatebw action") }
                </Paragraph>
                <CodeBlock language={codes['delegatebw'].type}>
                    {codes['delegatebw'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#create-account" 
                    text={ t("Create an account") }
                />
                <Paragraph>
                    { t("The three actions shown above are submitted as a single transaction") }
                </Paragraph>
                <CodeBlock language={codes['create-account'].type}>
                    {codes['create-account'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h3" link="#what-is-next" 
                    text={ t("What is the next step?") }
                />
                <Paragraph sx={{ my: 1 }}>
                    { t("After creating an account for your contract-1") }
                    <Link href={`${developersUsingMainnetURL}/deploy-issue-and-transfer-tokens`}>
                        { t("After creating an account for your contract-link1") }
                    </Link>
                    { t("After creating an account for your contract-2") }
                    <Link href={`${developersUsingMainnetURL}/deploy-your-own-smart-contract`}>
                        { t("After creating an account for your contract-link2") }
                    </Link>
                    { t("After creating an account for your contract-3") }
                </Paragraph>
            </Box>
        </Fragment>
    )
}

export default BasicUsages;