import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const RightSideBar = styled(Grid)(({ theme }) => ({
    '&.right_side_bar': {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
    }
}));

export default RightSideBar;