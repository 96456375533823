import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

import Layout from '../../constants/Layout';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        marginLeft: Layout.drawerWidth['md'],
        width: `calc(100% - ${Layout.drawerWidth['md']}px)`,
    },
    [theme.breakpoints.up('lg')]: {
        marginLeft: Layout.drawerWidth,
        width: `calc(100% - ${Layout.drawerWidth['lg']}px)`,
    },
    height: Layout.appBarHeight,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.main.light,
    '& .left_side_bar.mobile': {
        [theme.breakpoints.down('lg')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
    },
    '& .top_menu': {
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            alignItems: 'center'
        },
    },
}));

export default AppBar;