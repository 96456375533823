import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import theme from '../styles/theme';
import prototype from '../utilities/prototype';
import Layout from '../components/Layout';
import Welcome from '../pages/Welcome';
import GettingStarted from '../pages/GettingStarted';
import DevelopmentEnvironment from '../pages/GettingStarted/DevelopmentEnvironment';
import HelloSmartContract from '../pages/GettingStarted/HelloSmartContract';
import SmartContract from '../pages/SmartContract';
import DeployIssueTransferTokensUsingSingleNode from '../pages/SmartContract/DeployIssueTransferTokensUsingSingleNode';
import UsingMainnet from '../pages/SmartContract/UsingMainnet';
import BasicUsages from '../pages/SmartContract/UsingMainnet/BasicUsages';
import DeployIssueTransferTokens from '../pages/SmartContract/UsingMainnet/DeployIssueTransferTokens';
import DeployYourOwnContract from '../pages/SmartContract/UsingMainnet/DeployYourOwnContract';
import NotFound from './errors/NotFound';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter> 
                <Layout>
                    <Switch>
                        <Route exact path="/"> 
                            <Redirect to="/welcome" />
                        </Route>
                        <Route exact path="/welcome" component={Welcome} />
                        <Route exact path="/getting-started-guides" component={GettingStarted} />
                        <Route
                            path="/getting-started-guides/local-development-environment" 
                            component={DevelopmentEnvironment}
                        />
                        <Route
                            path="/getting-started-guides/hello-world-contract" 
                            component={HelloSmartContract}
                        />
                        <Route exact path="/smart-contract-guides" component={SmartContract}/>
                        <Route
                            path="/smart-contract-guides/deploy-issue-and-transfer-tokens-using-single-node" 
                            component={DeployIssueTransferTokensUsingSingleNode}
                        />
                        <Route
                            exact
                            path="/smart-contract-guides/smart-contract-using-mainnet" 
                            component={UsingMainnet}
                        />
                        <Route
                            path="/smart-contract-guides/smart-contract-using-mainnet/basic-usages" 
                            component={BasicUsages}
                        />
                        <Route
                            path="/smart-contract-guides/smart-contract-using-mainnet/deploy-issue-and-transfer-tokens" 
                            component={DeployIssueTransferTokens}
                        />
                        <Route
                            path="/smart-contract-guides/smart-contract-using-mainnet/deploy-your-own-smart-contract" 
                            component={DeployYourOwnContract}
                        />
                        <Route path="*" component={NotFound}></Route>
                    </Switch>
                </Layout>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
