const DeployIssueTransferTokens = {
    // create-token-contract
    'duplicate-source': {
        type: 'bash',
        contents: `cd ~
git clone https://github.com/EOSIO/eosio.token.git
mkdir mynewaccount
cp eosio.token/contracts/eosio.token/include/eosio.token/eosio.token.hpp mynewaccount
cp eosio.token/contracts/eosio.token/src/eosio.token.cpp mynewaccount
cd mynewaccount`
    },
    'rename-files': {
        type: 'bash',
        contents: `mv eosio.token.hpp mynewaccount.hpp
mv eosio.token.cpp mynewaccount.cpp`
    },
    'change-hpp': {
        type: 'cpp',
        contents: `class [[eosio::contract("eosio.token")]] token : public contract {

to

class [[eosio::contract("mynewaccount")]] token : public contract {`
    },
    'change-cpp': {
        type: 'cpp',
        contents: `#include <eosio.token/eosio.token.hpp>

to

#include "mynewaccount.hpp"`
    },
    'token-compile': {
        type: 'bash',
        contents: `cd ~/mynewaccount
eosio-cpp -abigen -abigen_output=mynewaccount.abi -o mynewaccount.wasm mynewaccount.cpp`
    },
    'token-compile-result': {
        type: 'bash',
        contents: `cd ~/mynewaccount
ls -al
.  ..  mynewaccount.abi  mynewaccount.cpp  mynewaccount.hpp  mynewaccount.wasm`
    },
    
    // deploy-contract
    'usage-setcode': {
        type: 'javascript',
        contents: `const fs = require('fs')
const wasmFilePath = '/mypath/my_smart_contract.wasm'
const wasmHexString = fs.readFileSync(wasmFilePath).toString('hex')`
    },
    'setcode': {
        type: 'javascript',
        contents: `{
    account: 'eosio',
    name: 'setcode',
    authorization: [
        {
            actor: 'mynewaccount',
            permission: 'active',
        },
    ],
    data: {
        account: 'mynewaccount',
        code: wasmHexString,
    },
}`
    },
    'usage-setabi': {
        type: 'javascript',
        contents: `const fs = require('fs')

const buffer = new Serialize.SerialBuffer({
    textEncoder: api.textEncoder,
    textDecoder: api.textDecoder,
})

const abiFilePath = '/mypath/my_smart_contract.abi'
let abiJSON = JSON.parse(fs.readFileSync(abiFilePath, 'utf8'))
const abiDefinitions = api.abiTypes.get('abi_def')

abiJSON = abiDefinitions.fields.reduce(
    (acc, { name: fieldName }) =>
        Object.assign(acc, { [fieldName]: acc[fieldName] || [] }),
        abiJSON
    )
abiDefinitions.serialize(buffer, abiJSON)
serializedAbiHexString = Buffer.from(buffer.asUint8Array()).toString('hex')`
    },
    'setabi': {
        type: 'javascript',
        contents: `{
    account: 'eosio',
    name: 'setabi',
    authorization: [
    {
        actor: 'mynewaccount',
        permission: 'active',
    },
    ],
    data: {
        account: 'mynewaccount',
        abi: serializedAbiHexString,
    },
}`
    },
    'deploy-contract': {
        type: 'javascript',
        contents: `const wasmFilePath = '/mypath/my_smart_contract.wasm'
const abiFilePath = '/mypath/my_smart_contract.abi'

const wasmHexString = fs.readFileSync(wasmFilePath).toString('hex')

const buffer = new Serialize.SerialBuffer({
    textEncoder: api.textEncoder,
    textDecoder: api.textDecoder,
})

let abiJSON = JSON.parse(fs.readFileSync(abiFilePath, 'utf8'))
const abiDefinitions = api.abiTypes.get('abi_def')
abiJSON = abiDefinitions.fields.reduce(
    (acc, { name: fieldName }) =>
        Object.assign(acc, { [fieldName]: acc[fieldName] || [] }),
        abiJSON
)
abiDefinitions.serialize(buffer, abiJSON)
serializedAbiHexString = Buffer.from(buffer.asUint8Array()).toString('hex')

await api.transact(
    {
        actions: [
            {
                account: 'eosio',
                name: 'setcode',
                authorization: [
                    {
                        actor: 'mynewaccount',
                        permission: 'active',
                    },
                ],
                data: {
                    account: 'mynewaccount',
                    code: wasmHexString,
                },
            },
            {
            account: 'eosio',
                name: 'setabi',
                authorization: [
                    {
                        actor: 'mynewaccount',
                        permission: 'active',
                    },
                ],
                data: {
                    account: 'mynewaccount',
                    abi: serializedAbiHexString,
                },
            },
        ],
    },
    {
        blocksBehind: 3,
        expireSeconds: 30,
    }
)`
    },

    // calling-token-actions
    'create-token': {
        type: 'javascript',
        contents: `(async () => {
    await api.transact({
        actions: [{
            account: 'mynewaccount',
            name: 'create',
            authorization: [{
                actor: 'mynewaccount',
                permission: 'active',
            }],
            data: {
                issuer: 'mynewaccount',
                maximum_supply: '10000000.0000 NEWT',
            }
        }]
    }, {
        blocksBehind: 3,
        expireSeconds: 30,
    });
})();`
    },
    'issue-token': {
        type: 'javascript',
        contents: `(async () => {
    await api.transact({
        actions: [{
            account: 'mynewaccount',
            name: 'transfer',
            authorization: [{
                actor: 'mynewaccount',
                permission: 'active',
            }],
            data: {
                issuer: 'mynewaccount',
                quantity: '10000.0000 NEWT',
                memo: 'some memo'
            }
        }]
    }, {
        blocksBehind: 3,
        expireSeconds: 30,
    });
})();`
    },
    'transfer-token': {
        type: 'javascript',
        contents: `(async () => {
    await api.transact({
        actions: [{
                account: 'mynewaccount',
                name: 'transfer',
                authorization: [{
                actor: 'mynewaccount',
                permission: 'active',
            }],
            data: {
                from: 'mynewaccount',
                to: 'otheraccount',
                quantity: '100.0000 NEWT',
                memo: 'some memo'
            }
        }]
    }, {
        blocksBehind: 3,
        expireSeconds: 30,
    });
})();`
    },
}

export default DeployIssueTransferTokens;