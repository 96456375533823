import BasicUsages from "./BasicUsages";
import DeployIssueTransferTokens from "./DeployIssueTransferTokens";
import DeployYourOwnContract from "./DeployYourOwnContract";

const usingMainnet = {
    BasicUsages,
    DeployIssueTransferTokens,
    DeployYourOwnContract
};

export default usingMainnet;