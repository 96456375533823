import { Fragment } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Title from "../../../components/pages/Title";
import LinkTitle from "../../../components/pages/LinkTitle";
import CodeBlock from "../../../components/pages/CodeBlock";
import Codes from '../../../constants/Codes';
import Paragraph from "../../../styles/components/Paragraph";

const DeployYourOwnContract = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.smartContact.usingMainnet.deployYourOwnContract' });
    const codes = Codes.SmartContract.UsingMainnet.DeployYourOwnContract;
    const developersUsingMainnetURL = `/smart-contract-guides/smart-contract-using-mainnet`;
    
    return (
        <Fragment>
            <Title text={ t("Deploy your own smart contract") } />

            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#before-you-start" 
                    text={ t("Before you start the tutorial") }
                />
                <Paragraph>
                    { t("please check if the mainnet development environment is set up-1") }
                    <Link href={`${developersUsingMainnetURL}/basic-usages`}>
                        { t("please check if the mainnet development environment is set up-link") }
                    </Link>
                    { t("please check if the mainnet development environment is set up-2") }
                </Paragraph>

                <LinkTitle 
                    variant="h2" link="#create-contract" 
                    text={ t("1. Generate a contract") }
                />
                <LinkTitle 
                    variant="h3" link="#create-custom-contract" 
                    text={ t("Generate a contract") }
                />
                <Paragraph>
                    { t("Set the smart contract mynewaccount and define the desired action-1") }
                    <Paragraph component="span" color="red">
                        { t("Set the smart contract mynewaccount and define the desired action-2") }
                        <Link href={`${developersUsingMainnetURL}/basic-usages`}>
                            { t("Set the smart contract mynewaccount and define the desired action-link") }
                        </Link>
                        { t("Set the smart contract mynewaccount and define the desired action-3") }
                    </Paragraph>
                </Paragraph>
                <CodeBlock language={codes['create-custom-contract'].type}>
                    {codes['create-custom-contract'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#compile-custom-contract" 
                    text={ t("Compile a contract") }
                />
                <CodeBlock language={codes['compile-custom-contract'].type}>
                    {codes['compile-custom-contract'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h2" link="#deploy-contract" 
                    text={ t("2. Deploy a contract") }
                />
                <Paragraph>
                    { t("You need to submit a transaction with two actions") }
                </Paragraph>
                <LinkTitle 
                    variant="h3" link="#setcode" 
                    text={ t("setcode") }
                />
                <Paragraph>
                    { t("\"setcode\" takes the name of the account where the smart contract") }
                </Paragraph>
                <CodeBlock language={codes['usage-setcode'].type}>
                    {codes['usage-setcode'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("In the example shown below") }
                </Paragraph>
                <CodeBlock language={codes['setcode'].type}>
                    {codes['setcode'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#setabi" 
                    text={ t("setabi") }
                />
                <Paragraph>
                    { t("\"setabi\" uses a serialized") }
                </Paragraph>
                <CodeBlock language={codes['usage-setabi'].type}>
                    {codes['usage-setabi'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#deploy" 
                    text={ t("Deploy") }
                />
                <Paragraph>
                    { t("The two actions shown above are submitted") }
                </Paragraph>
                <CodeBlock language={codes['deploy-contract'].type}>
                    {codes['deploy-contract'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h2" link="#calling-actions" 
                    text={ t("3. Call a action") }
                />
                <Paragraph>
                    { t("The code shown below is to call the action written in the deployed smart contract") }
                </Paragraph>
                <CodeBlock language={codes['calling-custom-actions'].type}>
                    {codes['calling-custom-actions'].contents}
                </CodeBlock>
            </Box>
        </Fragment>
    )
}

export default DeployYourOwnContract;