import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import Layout from '../../constants/Layout';

const LgUp = styled(MuiDrawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            width: Layout.drawerWidth['md']
        },
        [theme.breakpoints.up('md')]: {
            width: Layout.drawerWidth['lg']
        },
        boxSizing: 'border-box',
        border: 0,
        backgroundColor: theme.palette.grey[200]
    },
    '&.left_side_bar.desktop': {
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block'
        },
    },
}));

const LgDown = styled(MuiDrawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        backgroundColor: theme.palette.grey[200]
    },
}));

const Drawer = {
    LgUp,
    LgDown
};

export default Drawer