import styled from "styled-components";
import Highlight, { defaultProps } from 'prism-react-renderer';

const Pre = styled.pre`
    background-color: #F7F5F1;
    text-align: left;
    margin: 1em 0;
    padding: 1.5em;
    border-radius: 3px;
    line-height: 1.5em;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: scroll;
`;

const Line = styled.div`
    display: table-row;
`;

const LineNo = styled.span`
    display: table-cell;
    text-align: right;
    padding-right: 1em;
    user-select: none;
    opacity: 0.5;
`;

const LineContent = styled.span`
    display: table-cell;
`;

const CodeBlock = ({ children, language }) => {
    const theme = {
        plain: {color: '#393A34', backgroundColor: '#f6f8fa'},
        styles: [
            { style: {color: '#999988', fontStyle: 'italic'}, types: ['comment', 'prolog', 'doctype', 'cdata'] },
            { style: {color: '#45818e' }, types: ['string'] },
            { style: {color: '#996802' }, types: ['function'] },
            { style: {color: '#586e75' }, types: ['punctuation', 'operator'] },
            { style: {color: '#8B1EEF' }, types: ['keyword'] },
        ]
    };

    return (
        <Highlight
            {...defaultProps}
            code={children.trim()}
            language={language}
            theme={theme}
        >
        {({ className, tokens, getLineProps, getTokenProps }) => (
            <Pre className={className}>
                {tokens.map((line, i) => (
                    <Line key={i} {...getLineProps({ line, key: i })}>
                        <LineNo>{i + 1}</LineNo>
                        <LineContent>
                        {line.map((token, key) => (
                            <span key={key} {...getTokenProps({ token, key })} />
                        ))}
                        </LineContent>
                    </Line>
                ))}
            </Pre>
        )}
        </Highlight>
    );
};

export default CodeBlock;