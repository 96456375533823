import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

import Navs from '../../constants/Navs';
import ListSubheader from '../../styles/components/List/ListSubheader';
import Accordion from '../../styles/components/Accordion';
import Icon from '../../styles/components/Icon';
import LocaleMenu from './LocaleMenu';

const LeftSideMenu = ({ location }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'components.navs.leftSideMenu' });
    const leftSideNavs = Navs.leftSideNavs;

    return (
        <List 
            component="nav"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    { t("index") }
                </ListSubheader>
            }
            sx={{ 
                maxHeight: '100vh',
                overflow: 'auto' 
            }}
        >
            <LocaleMenu />
            {
                leftSideNavs.map((nav, index) => {
                    return (<SideNav key={index} {...nav} location={location} />);
                })
            }
        </List>
    )
}

const NavItem = ({ name, parent, collapsed, content, depth, location }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'constants.navs.leftSideNavs' });
    const href = parent ? `/${parent}/${name}` : `/${name}`;
    const itemDepth = depth ?? 0;
    const ArrowRightIcon = Icon.ArrowRightIcon;
    const ArrowDropDownIcon = Icon.ArrowDropDownIcon;

    return (
        <Link 
            href={href} underline="none"
        >
            <ListItemButton>
                <ListItemIcon sx={{ width: 30, minWidth: 30, ml: 1 * itemDepth }}>
                    {
                        parent && !collapsed ? null : 
                            (collapsed && location.path.indexOf(name) >= 0 ? 
                                <ArrowDropDownIcon /> : <ArrowRightIcon />)
                    }
                </ListItemIcon>
                <ListItemText 
                    primary={
                        <Typography
                            sx={{ typography: 'subtitle1' }} 
                            color={location.current === name ? "main.deep" : "common.black"}
                        >
                            { t(content) }
                        </Typography>
                    }
                />
            </ListItemButton>
        </Link>
    );
}

const CollapsedNavItem = (props) => {
    const { name, children, parent, depth, location } = props;
    let itemDepth = depth ?? 0;

    return (
        <Accordion expanded={location.path.indexOf(name) >= 0}>
            <NavItem {...props} collapsed={true} depth={itemDepth} />
            {
                children && children.length > 0 && (children.map((child, index) => {
                    return (
                        <List key={index} component="div" disablePadding>
                            {
                                child.children && child.children.length > 0 ? (
                                    <CollapsedNavItem 
                                        {...child} 
                                        parent={name}
                                        depth={itemDepth + 1}
                                        location={location}
                                    />
                                ) : (
                                    <NavItem 
                                        key={index}
                                        {...child} 
                                        collapsed={child.children && child.children.length > 0}
                                        parent={parent ? `${parent}/${name}` : name} 
                                        depth={itemDepth + 1}
                                        location={location}
                                    />
                                )
                            }
                        </List>
                    )
                }))
            }
        </Accordion>
    );
}

const SideNav = (props) => {
    return (
        props.children && props.children.length > 0 ?
            (<CollapsedNavItem {...props}/>) : (<NavItem {...props} collapsed={false} />)
    );
}

export default LeftSideMenu;