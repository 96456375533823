import React, { Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

import Navs from '../../constants/Navs';

const RightSideMenu = ({ location, hash, setHash }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'components.navs.rightSideMenu' });
    const rightSideNavs = Navs.rightSideNavs

    return (
    <Fragment>
        <Typography variant='caption' color="text.secondary">
            { t("index") }
        </Typography>
        <List>
            {
                rightSideNavs[location.current].map((nav, index) => {
                    return (
                        <MenuItem 
                            key={index} {...nav} 
                            translationPrefix={location.current.convertKebabToCamel()}
                            currentHash={hash} setCurrentHash={setHash}
                        />
                    )
                })
            }
        </List>
    </Fragment>
    );
}

const MenuItem = (props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'constants.navs.rightSideNavs' });
    const { name, to, children, depth, currentHash, setCurrentHash, translationPrefix } = props
    const itemDepth = depth ?? 1;

    return (
        <Fragment>
            <ListItem sx={{ pl: 1.5 * itemDepth }}>
                <Link 
                    component={HashLink} 
                    smooth
                    to={to} underline="none"
                    onClick={() => setCurrentHash(to)}
                >
                    
                    <Typography 
                        color={currentHash 
                                && currentHash === to ?
                                    "main.deep" 
                                    : "text.secondary"}
                        variant='caption'
                    >
                        {t(`${translationPrefix}.${name}`)}
                    </Typography>                
                </Link>
            </ListItem>
            {
                children && children.length > 0 && (
                    children.map((child, index) => {
                        return (
                            <MenuItem key={index} {...child} 
                                translationPrefix={translationPrefix}
                                currentHash={currentHash} setCurrentHash={setCurrentHash} 
                                depth={itemDepth + 1}
                            />
                        )
                    })
                )
            }
        </Fragment>
    );
}

export default RightSideMenu;