import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Navs from '../../constants/Navs';

const TopMenu = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'constants.navs.topNavs' });
    const topNavs = Navs.topNavs;

    return (
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }} className="top_menu">
            {
                topNavs.map((topNav, index) => {
                    return (
                        <Link key={index} href={topNav.name} underline="none">
                            <Typography
                                color="main.light"
                                noWrap
                                sx={{ 
                                    mr: index < topNavs.length - 1 ? 5 : 0,
                                    typography: 'subtitle1',
                                    fontWeight: 'fontWeightBold',
                                }}
                            >
                                { t(topNav.content) }
                            </Typography>
                        </Link>
                    )
                })
            }
            </Box>
    );
}

export default TopMenu;