import { styled } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';

const Typography = styled(MuiTypography)(({ theme }) => ({
    '& .MuiLink-root': {
        display: 'none',
    },
    '&:hover .MuiLink-root': {
        display: 'block',
        '&.link_for_h2': {
            marginLeft: '-28px',           
        },
        '&.link_for_h3': {
            marginLeft: '-24px',
            '& .MuiSvgIcon-root': {
                fontSize: theme.typography.h4.fontSize,
            }
        },
        '&.link_for_h4': {
            marginLeft: '-23px',
            '& .MuiSvgIcon-root': {
                fontSize: theme.typography.h5.fontSize,
            }
        }
    },
}));

export default Typography;