import { createTheme } from '@mui/material/styles';

const calcRem = (size) => `${size / 16}rem`;

const colors = {
    main: {
        light: "#8B1EEF",
        deep: "#5D20CD",
    },
    code: {
        function: "#996802",
        keyword: "#6aa84f",
        special: "#3d84c6",
        string: "#45818e",
        punctuation: "#586e75",
        operator: "#657b83",
    }
}

const fontSizes = {
    small: calcRem(14),
    base: calcRem(16),
    lg: calcRem(18),
    xl: calcRem(20),
    xxl: calcRem(22),
    xxxl: calcRem(24),
    titleSize: calcRem(50),
};

const paddings = {
    small: calcRem(8),
    base: calcRem(10),
    lg: calcRem(12),
    xl: calcRem(14),
    xxl: calcRem(16),
    xxxl: calcRem(18),
};

const margins = {
    small: calcRem(8),
    base: calcRem(10),
    lg: calcRem(12),
    xl: calcRem(14),
    xxl: calcRem(16),
    xxxl: calcRem(18),
};

const interval = {
    base: calcRem(50),
    lg: calcRem(100),
    xl: calcRem(150),
    xxl: calcRem(200),
};

const verticalInterval = {
    base: `${calcRem(10)} 0 ${calcRem(10)} 0`,
};

const components = {
    MuiContainer: {
        styleOverrides: {
            root: {
                padding: 0,
            },
        },
    },
    MuiLink: {
        styleOverrides: {
            root: {
                color: colors.main.light,
                textDecorationColor: colors.main.light,
                "&:hover": {
                    color: colors.main.deep,
                },
                "&:click": {
                    color: colors.main.deep,
                }
            },
        },
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                border: 0,
                boxShadow: 0
            },
        },
    },
    MuiList: {
        styleOverrides: {
            root: {
                padding: 0,
            },
        },
    },
    MuiListItem: {
        styleOverrides: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: 30,
                width: 30,
            },
        },
    },
};

const theme = createTheme({
    fontSizes,
    paddings,
    margins,
    interval,
    verticalInterval,
    components,
    palette: {
        main: {
            light: colors.main.light,
            dark: colors.main.light,
            deep: colors.main.deep
        },
        code: {
            function: colors.code.function,
        }
    },
    typography: {
        h1: {
            fontSize: "3rem",
            fontWeight: 400,
        },
        h2: {
            fontSize: "2rem",
            fontWeight: 400,
        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: 500,
        },
        h4: {
            fontSize: "1.25rem",
            fontWeight: 500,
        },
        h5: {
            fontSize: "1.15rem",
            fontWeight: 500,
        },
        h6: {
            fontSize: "1rem",
            fontWeight: 700,
        },
        function: {
            color: colors.code.function,
        },
        keyword: {
            color: colors.code.keyword,
        },
        special: {
            color: colors.code.special,
        },
        string: {
            color: colors.code.string,
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

export default theme;