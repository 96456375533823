import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useTranslation } from 'react-i18next';

import RightSideBar from "../styles/components/Grid";
import Icon from "../styles/components/Icon";
import Breadcrumbs from "../components/Breadcrumbs";
import RightSideMenu from "../components/navs/RightSideMenu";
import Layout from '../constants/Layout';
import Navs from '../constants/Navs';

const Content = (props) => {
    const { children, location } = props
    const { t } = useTranslation();
    const LaunchIcon = Icon.LaunchIcon;

    const [hash, setHash] = useState(location.hash);

    return (
        <Container
            sx={{ mt:`${Layout.appBarHeight}px` }}
        >
            <Grid 
                container
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={5}
                sx={{ p: 2 }}
            >
                <Grid item xs={12} md={8}>
                    <Box sx={{ pt: 3, px: 5 }}>
                        <Breadcrumbs location={location}/>
                        {children}
                    </Box>
                </Grid>
                <RightSideBar item xs={0} md={4} className="right_side_bar">
                    <Container 
                        sx={{ 
                            position: 'fixed', 
                            width: Layout.drawerWidth['lg'],
                            height: Layout.drawerWidth['lg'] * 2,
                            overflowY: 'auto'
                        }}
                    >
                        {/* Git */}
                        <Box sx={{ p: 2, mb:3, border: '1px solid #C5C5C5', borderRadius: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <GitHubIcon fontSize="subtitle1" sx={{ mr: 1 }}/>
                                <Typography variant='subtitle1'>
                                    { t("components.content.rightSideBarTitle") }
                                </Typography>
                            </Box>
                            <Divider sx={{ my: 1 }}/>
                            <Box sx={{ px: 2 }}>
                                {
                                    Navs.rightSideGithubNavs.map(((nav, index) => {
                                        return (
                                            <List key={index} sx={{ p: 0 }}>
                                                <Link
                                                    href={nav.url}
                                                    color="main.light"
                                                    underline="hover"
                                                    variant="subtitle2"
                                                    target="_blank"
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    {t(`constants.navs.rightSideGithubNavs.${nav.name}`)}
                                                    <LaunchIcon fontSize="inherit" sx={{ ml: 0.5 }}/>
                                                </Link>
                                            </List>
                                        )
                                    }))
                                }
                            </Box>
                            
                        </Box>
                        {/* On this page */}
                        <Box>
                            {
                                Navs.rightSideNavs[location.current] 
                                    && Navs.rightSideNavs[location.current].length > 0 &&
                                    (<RightSideMenu location={location} hash={hash} setHash={setHash}/>)
                            }
                        </Box>
                    </Container>
                </RightSideBar>
            </Grid>
        </Container>
    );
}

export default Content;