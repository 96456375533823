const DeployYourOwnContract = {
    // create-custom-token-contract
    'create-custom-contract': {
        type: 'cpp',
        contents: `#include <eosio/eosio.hpp>
class [[eosio::contract]] mynewaccount : public eosio::contract {
    public:
        using eosio::contract::contract;
        [[eosio::action]] void yourOwnAction( eosio::name user ) {
            require_auth( user ); // Authorization
            print( "yourOwnAction, ", name{user} );
        }
};`
    },
    'compile-custom-contract': {
        type: 'bash',
        contents: `eosio-cpp -abigen -o mynewaccount.wasm mynewaccount.cpp`
    },

    // deploy-contract
    'usage-setcode': {
        type: 'javascript',
        contents: `const fs = require('fs')
const wasmFilePath = '/mypath/my_smart_contract.wasm'
const wasmHexString = fs.readFileSync(wasmFilePath).toString('hex')`
    },
    'setcode': {
        type: 'javascript',
        contents: `{
    account: 'eosio',
    name: 'setcode',
    authorization: [
        {
            actor: 'mynewaccount',
            permission: 'active',
        },
    ],
    data: {
        account: 'mynewaccount',
        code: wasmHexString,
    },
}`
    },
    'usage-setabi': {
        type: 'javascript',
        contents: `const fs = require('fs')

const buffer = new Serialize.SerialBuffer({
    textEncoder: api.textEncoder,
    textDecoder: api.textDecoder,
})

const abiFilePath = '/mypath/my_smart_contract.abi'
let abiJSON = JSON.parse(fs.readFileSync(abiFilePath, 'utf8'))
const abiDefinitions = api.abiTypes.get('abi_def')

abiJSON = abiDefinitions.fields.reduce(
    (acc, { name: fieldName }) =>
        Object.assign(acc, { [fieldName]: acc[fieldName] || [] }),
        abiJSON
    )
abiDefinitions.serialize(buffer, abiJSON)
serializedAbiHexString = Buffer.from(buffer.asUint8Array()).toString('hex')`
    },
    'setabi': {
        type: 'javascript',
        contents: `{
    account: 'eosio',
    name: 'setabi',
    authorization: [
    {
        actor: 'mynewaccount',
        permission: 'active',
    },
    ],
    data: {
        account: 'mynewaccount',
        abi: serializedAbiHexString,
    },
}`
    },
    'deploy-contract': {
        type: 'javascript',
        contents: `const wasmFilePath = '/mypath/my_smart_contract.wasm'
const abiFilePath = '/mypath/my_smart_contract.abi'

const wasmHexString = fs.readFileSync(wasmFilePath).toString('hex')

const buffer = new Serialize.SerialBuffer({
    textEncoder: api.textEncoder,
    textDecoder: api.textDecoder,
})

let abiJSON = JSON.parse(fs.readFileSync(abiFilePath, 'utf8'))
const abiDefinitions = api.abiTypes.get('abi_def')
abiJSON = abiDefinitions.fields.reduce(
    (acc, { name: fieldName }) =>
        Object.assign(acc, { [fieldName]: acc[fieldName] || [] }),
        abiJSON
)
abiDefinitions.serialize(buffer, abiJSON)
serializedAbiHexString = Buffer.from(buffer.asUint8Array()).toString('hex')

await api.transact(
    {
        actions: [
            {
                account: 'eosio',
                name: 'setcode',
                authorization: [
                    {
                        actor: 'mynewaccount',
                        permission: 'active',
                    },
                ],
                data: {
                    account: 'mynewaccount',
                    code: wasmHexString,
                },
            },
            {
            account: 'eosio',
                name: 'setabi',
                authorization: [
                    {
                        actor: 'mynewaccount',
                        permission: 'active',
                    },
                ],
                data: {
                    account: 'mynewaccount',
                    abi: serializedAbiHexString,
                },
            },
        ],
    },
    {
        blocksBehind: 3,
        expireSeconds: 30,
    }
)`
    },

    // calling-custom-actions
    'calling-custom-actions': {
        type: 'javascript',
        contents: `(async () => {
    await api.transact({
        actions: [{
                account: 'mynewaccount',
                name: 'yourOwnAction',
                authorization: [{
                actor: 'mynewaccount',
                permission: 'active',
            }],
            data: {
                yourOwnActions's Data
            }
        }]
    }, {
        blocksBehind: 3,
        expireSeconds: 30,
    });
})();`
    },
}

export default DeployYourOwnContract;