import { Fragment } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Title from "../../components/pages/Title";
import TextList from "../../components/pages/TextList";
import Paragraph from "../../styles/components/Paragraph";

const Index = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.gettingStarted.index' });
    const mainnetURL = "https://github.com/bizamainet/bizamainet";
    const developersURL = "/getting-started-guides/local-development-environment";
    
    return (
        <Fragment>
            <Title text={ t("Getting started") } />

            <Box component="div">
                <Paragraph sx={{ mb: 3 }}>
                    { t("A local development environment is a local machine that satisfies  the system requirements") }
                </Paragraph>
                <Paragraph sx={{ my: 3 }}>
                    { t("Before you start-1") }
                    <Link 
                        href={`${mainnetURL}#supported-operating-systems`} 
                        target="_blank"
                    >
                        { t("Before you start-link") }
                    </Link>
                    { t("Before you start-2") }
                </Paragraph>
                <Paragraph sx={{ my: 3 }}>
                    { t("Here are steps to prepare your local development environment to build and deploy smart contracts") }
                </Paragraph>
                <Box variant="div" sx={{ my: 3 }}>
                    <TextList
                        type="decimal"
                        list={[
                            <Fragment>
                                <Link 
                                    href={`${mainnetURL}#prebuilt-binaries`} 
                                    target="_blank"
                                >
                                    { t("Install EOSIO binaries") }
                                </Link>
                                { t("Steps to install EOSIO pre-built binaries") }
                            </Fragment>,
                            <Fragment>
                                <Link href={`${developersURL}#install-eosio-cdt`}>
                                { t("Install CDT") }
                                </Link>
                                { t("Steps to install the EOSIO CDT(Contracts Development Kit) which will be a compiler") }
                            </Fragment>,
                            <Fragment>
                                <Link href={`${developersURL}#create-development-wallet`}>
                                { t("Create a Wallet for Development") }
                                </Link>
                                { t("Steps to create a development wallet to store the key pair") }
                            </Fragment>,
                            <Fragment>
                                <Link href={`${developersURL}#start-keosd-nodeos`}>
                                { t("Start Keosd & Nodeos") }
                                </Link>
                                { t("Steps to start Keosd and Nodeos") }
                            </Fragment>,
                            <Fragment>
                                <Link href={`${developersURL}#create-development-account`}>
                                { t("Create a Account for Development") }
                                </Link>
                                { t("Steps to create the first blockchain account for the smart contract") }
                            </Fragment>,
                        ]}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

// const GettingStarted = () => {
//     return (
//         <Routes>
//             <Route
//                 path="/" 
//                 element={<Index />}
//             />
//             <Route
//                 path="local-development-environment" 
//                 element={<DevelopmentEnvironment />}
//             />
//             <Route
//                 path="hello-world-contract" 
//                 element={<HelloSmartContract />}
//             />
//         </Routes>
//     )
// }

const GettingStarted = Index;

export default GettingStarted;