import { Fragment } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Title from "../../components/pages/Title"
import LinkTitle from "../../components/pages/LinkTitle";
import TextList from "../../components/pages/TextList";
import Paragraph from "../../styles/components/Paragraph";

const Index = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.smartContact.index' });
    const developersGettingStartedURL = "/getting-started-guides";
    const developersSmartContractURL = "/smart-contract-guides";

    return (
        <Fragment>
            <Title text={ t("Smart contract guideline") } />

            <Box component="div">
                <Paragraph sx={{ mb: 3 }}>
                    { t("In the smart contract guideline") }
                </Paragraph>

                <LinkTitle 
                    variant="h2" link="#before-you-begin" 
                    text={ t("Before you begin") }
                />
                <Paragraph sx={{ mb: 3 }}>
                    { t("Before starting the smart contract guideline-1") }
                    <Link href={`${developersGettingStartedURL}`}>
                        { t("Before starting the smart contract guideline-link") }
                    </Link>
                    { t("Before starting the smart contract guideline-2") }
                </Paragraph>

                <LinkTitle 
                    variant="h2" link="#install-eosjs" 
                    text={ t("Smart contract development guideline list") }
                />
                <Paragraph sx={{ mb: 3 }}>
                    { t("This smart contract guideline introduces") }
                </Paragraph>
                <Box variant="div" sx={{ my: 3 }}>
                    <TextList
                        type="decimal"
                        list={[
                            <Link 
                                sx={{ px: 0.5 }}
                                href={`${developersSmartContractURL}/deploy-issue-and-transfer-tokens-using-single-node`}
                            >
                                { t("Implementing & applying smart contracts in a local environment") }
                            </Link>,
                            <Link 
                                sx={{ px: 0.5 }}
                                href={`${developersSmartContractURL}/smart-contract-using-mainnet`}
                            >
                                { t("Implementing & applying smart contracts in a mainnet environment") }
                            </Link>
                        ]}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

// const SmartContract = () => {
//     return (
//         <Routes>
//             <Route
//                 path="/" 
//                 element={<Index />}
//             />
//             <Route
//                 path="deploy-issue-and-transfer-tokens-using-single-node" 
//                 element={<DeployIssueTransferTokensUsingSingleNode />}
//             />
//             <Route
//                 path="smart-contract-using-mainnet/*" 
//                 element={<UsingMainnet />}
//             />
//         </Routes>
//     )
// }

const SmartContract = Index;

export default SmartContract;