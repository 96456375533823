import { Fragment } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Title from "../../../components/pages/Title";
import LinkTitle from "../../../components/pages/LinkTitle";
import TextList from "../../../components/pages/TextList";
import Paragraph from "../../../styles/components/Paragraph";

const Index = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.smartContact.usingMainnet.index' });
    const developersURL = "/smart-contract-guides";
    const developersUsingMainnetURL = `${developersURL}/smart-contract-using-mainnet`;
    
    return (
        <Fragment>
            <Title text={ t("Deploy smart contracts on Mainnet") } />

            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#before-you-begin" 
                    text={ t("Before you start") }
                />
                <Paragraph sx={{ mb: 3 }}>
                    { t("If you want to test your smart contract-1") }
                    <Link href={`${developersURL}/deploy-issue-and-transfer-tokens-using-single-node`}>
                        { t("If you want to test your smart contract-link") }
                    </Link>
                    { t("If you want to test your smart contract-2") }
                </Paragraph>

                <LinkTitle 
                    variant="h2" link="#install-eosjs" 
                    text={ t("Process for smart contract deployment on mainnet") }
                />
                <Paragraph sx={{ mb: 3 }}>
                    { t("In this guide-1") }
                    <Link href={`${developersUsingMainnetURL}/basic-usages`}>
                        { t("In this guide-link") }
                    </Link>
                    { t("In this guide-2") }
                </Paragraph>
                <Box variant="div" sx={{ my: 3 }}>
                    <Paragraph sx={{ my: 1 }}>
                        { t("After setting up the environment for development on the mainnet") }
                    </Paragraph>
                    <TextList
                        type="decimal"
                        list={[
                            <Link 
                                sx={{ px: 0.5 }}
                                href={`${developersUsingMainnetURL}/deploy-issue-and-transfer-tokens`}
                            >
                                { t("Issue a token on the mainnet") }
                            </Link>,
                            <Link 
                                sx={{ px: 0.5 }}
                                href={`${developersUsingMainnetURL}/deploy-your-own-smart-contract`}
                            >
                                { t("Deploy your own smart contract") }
                            </Link>
                        ]}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

// const UsingMainnet = () => {
//     return (
//         <Routes>
//             <Route
//                 path="/" 
//                 element={<Index />}
//             />
//             <Route
//                 path="basic-usages" 
//                 element={<BasicUsages />}
//             />
//             <Route
//                 path="deploy-issue-and-transfer-tokens" 
//                 element={<DeployIssueTransferTokens />}
//             />
//             <Route
//                 path="deploy-your-own-smart-contract" 
//                 element={<DeployYourOwnContract />}
//             />
//         </Routes>
//     )
// }

const UsingMainnet = Index;

export default UsingMainnet;