import Box from '@mui/material/Box';

import useCurrentLocation from '../hooks/useCurrentLocation';
import LeftSideNav from './LeftSideNav';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';

const Layout = ({ children }) => {
    const location = useCurrentLocation();

    return (
        <Box sx={{ display: 'flex' }}>
            <LeftSideNav location={location} />
            <Box 
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Header location={location} />
                
                <Content location={location}>{children}</Content>                

                <Footer />
            </Box>
        </Box>
    )
}

export default Layout;