const locales = [
  {
    "name": "Korean",
    "code": "ko"
  },
  {
    "name": "English",
    "code": "en"
  }
];

export default locales;