import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

import Title from "../../components/pages/Title";
import LinkTitle from "../../components/pages/LinkTitle";
import CodeBlock from "../../components/pages/CodeBlock";
import Codes from '../../constants/Codes';
import Paragraph from "../../styles/components/Paragraph";

const HelloSmartContract = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.gettingStarted.helloSmartContract' });
    const codes = Codes.GettingStarted.HelloSmartContract;

    return (
        <Fragment>
            <Title text={ t("Build & deploy smart contract") } />

            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#create-contract" 
                    text={ t("1. Create a contract") }
                />
                <Paragraph>
                    { t("Create a Hello World smart contract by following these steps") }
                </Paragraph>
                <Paragraph>
                    { t("Create two files") }
                </Paragraph>

                <LinkTitle 
                    variant="h3" link="#procedure-hellocpp-file" 
                    text={ t("How to create a hello.cpp file") }
                />
                <Paragraph>
                    { t("1. First, create a folder called hello to store the smart contract") }
                </Paragraph>
                <CodeBlock language={codes['create-hello-folder'].type}>
                    {codes['create-hello-folder'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("2. Create a hello.cpp file") }
                </Paragraph>
                <CodeBlock language={codes['create-hello-cpp'].type}>
                    {codes['create-hello-cpp'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("3. Open your preferred text editor") }
                </Paragraph>
                <Paragraph sx={{ pl: 1 }}>
                    { t("a. Import the eosio core library using the include directive") }
                </Paragraph>
                <CodeBlock language={codes['include-eosio-hpp'].type}>
                    {codes['include-eosio-hpp'].contents}
                </CodeBlock>
                <Paragraph sx={{ pl: 1 }}>
                    { t("b. eosio.hpp contains the classes needed to write a smart contract") }
                </Paragraph>
                <CodeBlock language={codes['add-eosio-contract'].type}>
                    {codes['add-eosio-contract'].contents}
                </CodeBlock>
                <Paragraph sx={{ pl: 1 }}>
                    { t("c. Add a public access specifier") }
                </Paragraph>
                <CodeBlock language={codes['using-eosio-contract'].type}>
                    {codes['using-eosio-contract'].contents}
                </CodeBlock>
                <Paragraph sx={{ pl: 1 }}>
                    { t("d. Add a public hi action") }
                </Paragraph>
                <CodeBlock language={codes['add-hi-action'].type}>
                    {codes['add-hi-action'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("Your hello.cpp file should look as following") } 
                </Paragraph>
                <CodeBlock language={codes['hello-cpp'].type}>
                    {codes['hello-cpp'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("4. Save the file") }
                </Paragraph>

                <LinkTitle 
                    variant="h2" link="#compile-deploy" 
                    text={ t("2. Compile & deploy") }
                />
                <LinkTitle 
                    variant="h3" link="#procedure-compile-deploy" 
                    text={ t("Compile & deploy process") }
                />
                <Paragraph>
                    { t("1. Compile the hello.cpp file using the eosio-cpp command") }
                </Paragraph>
                <CodeBlock language={codes['compile'].type}>
                    {codes['compile'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("Deploy the compiled hello.wasm-1") }
                    <Link href="/getting-started-guides/local-development-environment#create-development-account">
                        { t("Deploy the compiled hello.wasm-link") }
                    </Link>
                    { t("Deploy the compiled hello.wasm-2") }
                </Paragraph>
                <CodeBlock language={codes['deploy'].type}>
                    {codes['deploy'].contents}
                </CodeBlock>
                
                <LinkTitle 
                    variant="h2" link="#calling-smart-contract-action" 
                    text={ t("3. Request a smart contract action") }
                />
                <LinkTitle 
                    variant="h3" link="#procedure-calling-hi-action" 
                    text={ t("Hi action request process") }
                />
                <Paragraph>
                    { t("Use cleos push action") }
                </Paragraph>
                <CodeBlock language={codes['calling-action'].type}>
                    {codes['calling-action'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("A message confirming that the transaction has been broadcasted will be shown") }
                </Paragraph>
                <CodeBlock language={codes['calling-action-result'].type}>
                    {codes['calling-action-result'].contents}
                </CodeBlock>
            </Box>
        </Fragment>
    )
}

export default HelloSmartContract;