import { HashLink } from 'react-router-hash-link';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';

import Typography from '../../styles/components/Typography';

const LinkTitle = ({ variant, link, text, sx }) => {
    return (
        <Box 
            component="div" 
            sx={[{ 
                display: "flex", 
                flexDirection: "row", 
                alignItems: "center",
                my: variant === "h1" || variant === "h2" ? 3 : (
                        variant === "h3" ? 2.5 : 2
                    )
            }, sx]}
            id={link.replace('#', '')}
        >
            <Typography 
                variant={variant}
                sx={{ width: '100%' }}
            >
                <Link 
                    component={HashLink}
                    smooth to={link} sx={{ display: "flex", alignItems: "center", float: "left", pr: 0.5 }}
                    className={`link_for_${variant}`}
                >
                    <LinkIcon />
                </Link>
                {text}
            </Typography>
        </Box>
    );
}

export default LinkTitle