import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

import locales from '../../constants/Locales';
import i18n from '../../locales';

const SelectWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '-5px',
    right: '0px',
    zIndex: 100
}));

const Select = styled(MuiSelect)(({ theme }) => ({
    color: theme.palette.main.light,
    fontWeight: 600,
    marginRight: '20px',
    '& svg': {
        color: theme.palette.main.light,
    },
    '& fieldset': {
        border: 0,
    },
    '& .locale_img': {
        width: '25px',
        height: '25px',
        marginRight: '10px',
        verticalAlign: 'middle'
    }
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    '&.Mui-selected': {
        color: theme.palette.main.light,
    },
    '& .locale_img': {
        width: '25px',
        height: '25px',
        marginRight: '10px'
    }
}));

const LocaleMenu = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'constants.locales' });
    const selectedLocale = localStorage.getItem('locale') || i18n.language;
    const onChangeLocale = (code) => {
        i18n.changeLanguage(code)
        localStorage.setItem('locale', code)
    }

    return (
        <SelectWrapper>
            <Select
                className="locale_menu"
                value={selectedLocale}
                onChange={(e) => onChangeLocale(e.target.value)}
            >
                {
                    locales.map((locale, index) => (
                        <MenuItem key={index} value={locale.code}>
                            <Box
                                className="locale_img"
                                component="img"
                                alt="locale"
                                src={require(`../../assets/images/${locale.code}.png`)}
                            />
                            { t(locale.name) }
                        </MenuItem>
                    ))
                }
            </Select>
        </SelectWrapper>
    );
}

export default LocaleMenu;