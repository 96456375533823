import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';

const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: 0,
        backgroundColor: theme.palette.grey[200],
        '&:before': {
        display: 'none',
    },
}));

export default Accordion;