import { styled } from '@mui/material/styles';

import MuiLaunchIcon from '@mui/icons-material/Launch';
import MuiArrowRightIcon from '@mui/icons-material/ArrowRight';
import MuiArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const LaunchIcon = styled(MuiLaunchIcon)(({ theme }) => ({
    color: theme.palette.common.black,
}));

const ArrowRightIcon = styled(MuiArrowRightIcon)(({ theme }) => ({
    color: theme.palette.main.deep,
}));

const ArrowDropDownIcon = styled(MuiArrowDropDownIcon)(({ theme }) => ({
    color: theme.palette.main.deep,
}));

const Icon = {
    LaunchIcon,
    ArrowRightIcon,
    ArrowDropDownIcon
};

export default Icon;