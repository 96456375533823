const HelloSmartContract = {
    // procedure-hellocpp-file
    'create-hello-folder': {
        type: 'bash',
        contents: `mkdir hello
cd hello`
    },
    'create-hello-cpp': {
        type: 'bash',
        contents: `touch hello.cpp`
    },
    'include-eosio-hpp': {
        type: 'cpp',
        contents: `#include <eosio/eosio.hpp>`
    },
    'add-eosio-contract': {
        type: 'cpp',
        contents: `class [[eosio::contract]] hello : public eosio::contract {};`
    },
    'using-eosio-contract': {
        type: 'cpp',
        contents: `public:
    using eosio::contract::contract;`
    },
    'add-hi-action': {
        type: 'cpp',
        contents: `[[eosio::action]] void hi( eosio::name user ) {
    print( "Hello, ", user);
}`
    },
    'hello-cpp': {
        type: 'cpp',
        contents: `#include <eosio/eosio.hpp>
class [[eosio::contract]] hello : public eosio::contract {
    public:
        using eosio::contract::contract;
        [[eosio::action]] void hi( eosio::name user ) {
            print( "Hello, ", user);
        }
};`
    },

    // compile-deploy
    'compile': {
        type: 'bash',
        contents: `eosio-cpp -abigen -o hello.wasm hello.cpp`
    },
    'deploy': {
        type: 'bash',
        contents: `cleos set contract hello ./hello -p hello@active`
    },

    // calling-smart-contract-action
    'calling-action': {
        type: 'bash',
        contents: `cleos push action hello hi'["john"]'-p john@active`
    },
    'calling-action-result': {
        type: 'bash',
        contents: `executed transaction: 28d92256c8ffd8b0255be324e4596b7c745f50f85722d0c4400471bc184b9a16  244 bytes  1000 cycles
#    hello.code <= hello.code::hi               {"user":"john"}
>> Hello, john`
    },
}

export default HelloSmartContract;