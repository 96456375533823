import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link
                    color="inherit"
                    href="https://amaxg.net/"
                    target="_blank"
                >
                AMAXG
                </Link>{' '}
                2022
        </Typography>
    );
}

const Footer = () => {
    return (
        <Box component="footer"  sx={{ mt: 4, mb: 4 }}>
            <Container maxWidth="lg">
                <Copyright sx={{ pt: 4 }} />
            </Container>
        </Box>
    );
}

export default Footer;