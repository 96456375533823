import Typography from '@mui/material/Typography';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';

import BreadcrumbItems from "../constants/Breadcrumbs";

const Breadcrumbs = ({ location }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'constants.breadcrumbs' });
    const items = BreadcrumbItems[location.current];

    return (
        items && items.length > 0 && 
        (
            <MuiBreadcrumbs 
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb" sx={{ mb: 2 }}
            >
                <Link 
                    underline="hover" color="inherit" href="/"
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <HomeIcon />
                </Link>
                {
                    items.map((item, index) => {
                        return (
                            index < items.length - 1 ?
                            (
                                <Link key={index} underline="hover" color="inherit" href={item.to}>
                                    { t(item.name) }
                                </Link>
                            ) : 
                            (
                                <Typography key={index} sx={{ fontWeight: 700 }}>
                                    { t(item.name) }
                                </Typography>
                            )
                        );
                    })
                }
            </MuiBreadcrumbs>
        )
    );
}

export default Breadcrumbs;