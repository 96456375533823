import { useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import StyledAppBar from '../styles/components/AppBar';
import StyledDrawer from '../styles/components/Drawer';
import Layout from '../constants/Layout';
import Menu from './navs/LeftSideMenu';
import TopMenu from './navs/TopMenu';

const Header = ({ location }) => {
    const [openMenu, setOpenMenu] = useState(false);

    const padding = 1;
    const Drawer = StyledDrawer.LgDown;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <StyledAppBar position="absolute">
                <Toolbar 
                    sx={{ justifyContent: 'flex-start' }} 
                    id="back-to-top-anchor"
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }} className="left_side_bar mobile">
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 }}
                            onClick={() => setOpenMenu(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box 
                            component="img"
                            sx={{
                                maxHeight: Layout.appBarHeight - (padding * 8 * 2),
                                maxWidth: Layout.drawerWidth['lg'] - (padding * 8 * 2),
                                py: padding
                            }}
                            alt="logo"
                            src={require('../assets/images/logo.png')}
                        />
                    </Box>
                    
                    <TopMenu />
                    
                </Toolbar>
            </StyledAppBar>
            <Drawer
                anchor={'left'}
                open={openMenu}
                onClose={() => setOpenMenu(false)}
            >
                <Menu location={location} />
            </Drawer>
        </Box>
    )
}

export default Header;