import { Fragment } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Title from "../components/pages/Title";
import LinkTitle from "../components/pages/LinkTitle";
import Paragraph from "../styles/components/Paragraph";

const Welcome = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.welcome' });

    return (
        <Fragment>
            <Title text={ t("Hello, BizAuto World!") } />

            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#welcome" 
                    text={ t("Welcome to the BizAuto Docs for Developers Guide Portal") } 
                />

                <Paragraph sx={{ my: 3 }}>
                    { t("This BizAuto Developer Portal is built for the EOSIO blockchain developer community") }
                </Paragraph>
                <Paragraph sx={{ my: 3 }}>
                    { t("If you are a developer who has never used EOSIO software") }
                </Paragraph>

                <LinkTitle 
                    variant="h2" link="#new-blockchain" 
                    text={ t("Are you a new blockchain developer?") } 
                />
                <Paragraph sx={{ my: 3 }}>
                    { t("In the BizAuto Developer Portal") }
                </Paragraph>
                <LinkTitle 
                    variant="h3" link="#getting-started-guides" 
                    text={ t("Getting started guide") } 
                />
                <Paragraph sx={{ my: 3 }}>
                    { t("You can learn the basics of BizAuto development environment and smart contract development-1") }
                    <Link href="/getting-started-guides">
                        { t("You can learn the basics of BizAuto development environment and smart contract development-link") }
                    </Link>
                    { t("You can learn the basics of BizAuto development environment and smart contract development-2") }
                </Paragraph>

                <LinkTitle 
                    variant="h2" link="#smart-contract" 
                    text={ t("Do you want to know more about smart contracts?") } 
                />
                <Paragraph sx={{ my: 3 }}>
                    { t("If you want to know about smart contracts-1") }
                    <Link href="/smart-contract-guides">
                        { t("If you want to know about smart contracts-link") }
                    </Link>
                    { t("If you want to know about smart contracts-2") }
                </Paragraph>
            </Box>  
        </Fragment>
    )
}

export default Welcome;