import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.errors.404' });

  return (
    <div>
      <h1>{ t("title") }</h1>
      <Link href="/" underline="none">
          <Typography color="text.secondary" variant='caption'>
            { t("link") }
          </Typography>
      </Link>
    </div>
  )
};

export default NotFound;