import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useCurrentLocation = () => {
    const location = useLocation();
    const current = location.pathname.split('/');

    useEffect(() => {
        if(location.hash) {
            const hash = document.getElementById(location.hash.replace('#', ''));
            if(hash) {
                hash.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [location.hash]);
    
    return {
        parent: current.length > 2 ? current[current.length - 2] : null,
        current: current[current.length - 1],
        path: current,
        hash: location.hash
    };
}

export default useCurrentLocation;