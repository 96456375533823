import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Title = ({ text }) => {
    return (
    <Box component="div" sx={{ display: "flex" }}>
            <Typography 
                variant="h1"
                sx={{ 
                    width: '100%',
                    borderBottom: '4px solid black',
                    mb: 5
                }}
            >
                {text}
            </Typography>
        </Box>
    );
}

export default Title;