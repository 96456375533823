import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

import Layout from '../constants/Layout';
import Menu from './navs/LeftSideMenu';
import StyledDrawer from '../styles/components/Drawer';

const LeftSideNav = ({ location }) => {
    const Drawer = StyledDrawer.LgUp;
    return (
        <Drawer variant="permanent" className='left_side_bar desktop'>
            <Logo />
            <Box sx={{ px: 2 }}><Divider /></Box>
            <Menu location={location} />
        </Drawer>
    )
}

const Logo = () => {
    const padding = 1;
    return (
        <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }}
        >
            <Box 
                component="img"
                sx={{
                    maxHeight: Layout.appBarHeight - (padding * 8 * 2),
                    maxWidth: Layout.drawerWidth['lg'] - (padding * 8 * 2),
                    py: padding
                }}
                alt="logo"
                src={require('../assets/images/logo.png')}
            />
        </Toolbar>
    );
}

export default LeftSideNav;