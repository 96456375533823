const Breadcrumbs = {
    'welcome': [
        {
            name: 'welcome',
        }
    ],
    'getting-started-guides': [
        {
            name: 'gettingStartedGuides'
        }
    ],
    'local-development-environment': [
        {
            name: 'gettingStartedGuides',
            to: '/getting-started-guides'
        },
        {
            name: 'localDevelopmentEnvironment',
        }
    ],
    'hello-world-contract': [
        {
            name: 'gettingStartedGuides',
            to: '/getting-started-guides'
        },
        {
            name: 'helloWorldContract',
        }
    ],
    'smart-contract-guides': [
        {
            name: 'smartContractGuides'
        }
    ],
    'deploy-issue-and-transfer-tokens-using-single-node': [
        {
            name: 'smartContractGuides',
            to: '/smart-contract-guides'
        },
        {
            name: 'deployIssueAndTransferTokensUsingSingleNode',
        }
    ],
    'smart-contract-using-mainnet': [
        {
            name: 'smartContractGuides',
            to: '/smart-contract-guides'
        },
        {
            name: 'basicUsages',
        }
    ],
    'basic-usages': [
        {
            name: 'smartContractGuides',
            to: '/smart-contract-guides'
        },
        {
            name: 'basicUsages',
            to: '/smart-contract-guides/smart-contract-using-mainnet'
        },
        {
            name: 'mainnetDevelopmentEnvironment',
        }
    ],
    'deploy-issue-and-transfer-tokens': [
        {
            name: 'smartContractGuides',
            to: '/smart-contract-guides'
        },
        {
            name: 'basicUsages',
            to: '/smart-contract-guides/smart-contract-using-mainnet'
        },
        {
            name: 'deployIssueAndTransferTokens',
        }
    ],
    'deploy-your-own-smart-contract': [
        {
            name: 'smartContractGuides',
            to: '/smart-contract-guides'
        },
        {
            name: 'basicUsages',
            to: '/smart-contract-guides/smart-contract-using-mainnet'
        },
        {
            name: 'deployYourOwnSmartContract',
        }
    ],
};

export default Breadcrumbs;