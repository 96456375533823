const DevelopmentEnvironment = {
    // prepare-your-environment
    'install-eos-cdt-mac-os-x': {
        type: 'bash',
        contents: `brew tap eosio/eosio.cdt
brew install eosio.cdt`
    },
    'uninstall-eos-cdt-mac-os-x': {
        type: 'bash',
        contents: `brew remove eosio.cdt`
    },
    'install-eos-cdt-ubuntu-18-04': {
        type: 'bash',
        contents: `wget https://github.com/eosio/eosio.cdt/releases/download/v1.8.1/eosio.cdt_1.8.1-1-ubuntu-18.04_amd64.deb

sudo apt install ./eosio.cdt_1.8.1-1-ubuntu-18.04_amd64.deb`
    },
    'uninstall-eos-cdt-ubuntu-18-04': {
        type: 'bash',
        contents: `sudo apt remove eosio.cdt`
    },
    'install-eos-cdt-centos-rpm': {
        type: 'bash',
        contents: `wget https://github.com/eosio/eosio.cdt/releases/download/v1.8.1/eosio.cdt-1.8.1-1.el7.x86_64.rpm

sudo yum install ./eosio.cdt-1.8.1-1.el7.x86_64.rpm`
    },
    'uninstall-eos-cdt-centos-rpm': {
        type: 'bash',
        contents: `sudo yum remove eosio.cdt`
    },
    'install-from-source-move-directory': {
        type: 'bash',
        contents: `cd CONTRACTS_DIR`
    },
    'install-from-source-download': {
        type: 'bash',
        contents: `git clone --recursive https://github.com/eosio/eosio.cdt --branch v1.8.0 --single-branch
cd eosio.cdt`
    },
    'install-from-source-build': {
        type: 'bash',
        contents: `./build.sh`
    },
    'install-from-source': {
        type: 'bash',
        contents: `sudo ./install.sh`
    },

    // create-deveploment-wallet
    'create-wallet': {
        type: 'bash',
        contents: `cleos wallet create --to-console`
    },
    'create-wallet-result': {
        type: 'bash',
        contents: `Creating wallet: default
Save password to use in the future to unlock this wallet.
Without password imported keys will not be retrievable.
"PW5Kewn9L76X8Fpd....................t42S9XCw2"`
    },
    'open-wallet': {
        type: 'bash',
        contents: `cleos wallet open`
    },
    'wallet-list': {
        type: 'bash',
        contents: `cleos wallet list`
    },
    'wallet-list-result': {
        type: 'bash',
        contents: `Wallets:
[
    "default"
]`
    },
    'unlock-wallet': {
        type: 'bash',
        contents: `cleos wallet unlock`
    },
    'unlock-wallet-list-result': {
        type: 'bash',
        contents: `Wallets:
[
    "default *"
]`
    },

    // import-keys-in-wallet
    'create-key': {
        type: 'bash',
        contents: `cleos wallet create_key`
    },
    'create-key-result': {
        type: 'bash',
        contents: `Created new private key with a public key of: "EOS8PEJ5FM42xLpHK...X6PymQu97KrGDJQY5Y"`
    },

    // import-development-key
    'import-key': {
        type: 'bash',
        contents: `cleos wallet import`
    },
    'import-key-result': {
        type: 'bash',
        contents: `5KQwrPbwdL6PhXujxW37FSSQZ1JiwsST4cqQzDeyXtP79zkvFD3`
    },

    // start-keosd-nodeos
    'start-keosd': {
        type: 'bash',
        contents: `keosd &`
    },
    'start-keosd-result': {
        type: 'bash',
        contents: `info  2018-11-26T06:54:24.789 thread-0  wallet_plugin.cpp:42          plugin_initialize    ] initializing wallet plugin
info  2018-11-26T06:54:24.795 thread-0  http_plugin.cpp:554           add_handler          ] add api url: /v1/keosd/stop
info  2018-11-26T06:54:24.796 thread-0  wallet_api_plugin.cpp:73      plugin_startup       ] starting wallet_api_plugin
info  2018-11-26T06:54:24.796 thread-0  http_plugin.cpp:554           add_handler          ] add api url: /v1/wallet/create
info  2018-11-26T06:54:24.796 thread-0  http_plugin.cpp:554           add_handler          ] add api url: /v1/wallet/create_key
info  2018-11-26T06:54:24.796 thread-0  http_plugin.cpp:554           add_handler          ] add api url: /v1/wallet/get_public_keys`
    },
    'start-nodeos': {
        type: 'bash',
        contents: `nodeos -e -p eosio \\
--plugin eosio::producer_plugin \\
--plugin eosio::producer_api_plugin \\
--plugin eosio::chain_api_plugin \\
--plugin eosio::http_plugin \\
--plugin eosio::history_plugin \\
--plugin eosio::history_api_plugin \\
--filter-on="*" \\
--access-control-allow-origin='*' \\
--contracts-console \\
--http-validate-host=false \\
--verbose-http-errors >> nodeos.log 2>&1 &`
    },
    'check-nodeos': {
        type: 'bash',
        contents: `curl http://localhost:8888/v1/chain/get_info`
    },

    // create-deveploment-account
    'create-test-account': {
        type: 'bash',
        contents: `cleos create account eosio john YOUR_PUBLIC_KEY
cleos create account eosio lisa YOUR_PUBLIC_KEY`
    },
    'create-test-account-result': {
        type: 'bash',
        contents: `executed transaction: 40c605006de...  200 bytes  153 us
#         eosio <= eosio::newaccount            {"creator":"eosio","name":"lisa","owner":{"threshold":1,"keys":[{"key":"EOS5rti4LTL53xptjgQBXv9HxyU...
warning: transaction executed locally, but may not be confirmed by the network yet    ]`
    },
    'check-test-account': {
        type: 'bash',
        contents: `cleos get account lisa`
    },
    'check-test-account-result': {
        type: 'bash',
        contents: `permissions:
    owner     1:    1 EOS6MRyAjQq8ud7hVNYcfnVPJqcVpscN5So8BhtHuGYqET5GDW5CV
        active     1:    1 EOS6MRyAjQq8ud7hVNYcfnVPJqcVpscN5So8BhtHuGYqET5GDW5CV
memory:
    quota:       unlimited  used:      3.758 KiB

net bandwidth:
    used:               unlimited
    available:          unlimited
    limit:              unlimited

cpu bandwidth:
    used:               unlimited
    available:          unlimited
    limit:              unlimited`
    }
}

export default DevelopmentEnvironment;