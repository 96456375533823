import { Fragment } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Title from "../../components/pages/Title";
import LinkTitle from "../../components/pages/LinkTitle";
import CodeBlock from "../../components/pages/CodeBlock";
import TextList from "../../components/pages/TextList";
import Codes from '../../constants/Codes';
import Paragraph from "../../styles/components/Paragraph";

const DeployIssueTransferTokensUsingSingleNode = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.smartContact.deployIssueTransferTokens' });
    const codes = Codes.SmartContract.DeployIssueTransferTokensUsingSingleNode;
    
    return (
        <Fragment>
            <Title text={ t("Issue a token in a local environment") } />

            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#obtain-contract-source" 
                    text={ t("1. Configure the contract source code") }
                />
                <Paragraph>
                    { t("Go to the contract folder") }
                </Paragraph>
                <CodeBlock language={codes['move-contract-folder'].type}>
                    {codes['move-contract-folder'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("Get the source code from the folder") }
                </Paragraph>
                <CodeBlock language={codes['obtain-contract-source'].type}>
                    {codes['obtain-contract-source'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("This repository contains several contracts") }
                </Paragraph>
                <CodeBlock language={codes['move-eos-contract-folder'].type}>
                    {codes['move-eos-contract-folder'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h2" link="#create-account-for-contract" 
                    text={ t("2. Create a contract account") }
                />
                <Paragraph>
                    { t("Before deploying the token contract") }
                </Paragraph>
                <CodeBlock language={codes['create-account-for-contract'].type}>
                    {codes['create-account-for-contract'].contents}
                </CodeBlock>
                
                <LinkTitle 
                    variant="h2" link="#compile-contract" 
                    text={ t("3. Compile the contract") }
                />
                <CodeBlock language={codes['compile-contract'].type}>
                    {codes['compile-contract'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h2" link="#deploy-contract" 
                    text={ t("4. Deploy the contract") }
                />
                <CodeBlock language={codes['deploy-contract'].type}>
                    {codes['deploy-contract'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("When you deploy the contract") }
                </Paragraph>
                <CodeBlock language={codes['deploy-contract-result'].type}>
                    {codes['deploy-contract-result'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h2" link="#create-token" 
                    text={ t("5. Create a token") }
                />
                <Paragraph>
                    { t("To create a new token") }
                </Paragraph>
                <TextList
                    type="disc"
                    list={[
                        t("The issuer is an account and it is lisa in this case").trim(),
                        t("The asset type consists of two pieces of data").trim(),
                    ]}
                />
                <CodeBlock language={codes['create-token'].type}>
                    {codes['create-token'].contents}
                </CodeBlock>
                
                <Paragraph>
                    { t("The command shown above creates a new SYS token") }
                </Paragraph>
                <Paragraph>
                    { t("You can also use named arguments instead of the above command as follows") }
                </Paragraph>
                <CodeBlock language={codes['create-token-alternative'].type}>
                    {codes['create-token-alternative'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("When you run the command shown above") }
                </Paragraph>
                <CodeBlock language={codes['create-token-result'].type}>
                    {codes['create-token-result'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h2" link="#issue-token" 
                    text={ t("6. Issue a token") }
                />
                <Paragraph>
                    { t("Lisa,the issuer,can now issue a new SYS token") }
                </Paragraph>
                <CodeBlock language={codes['issue-token'].type}>
                    {codes['issue-token'].contents}
                </CodeBlock>
                <CodeBlock language={codes['issue-token-result'].type}>
                    {codes['issue-token-result'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("Try the -d-j option") }
                </Paragraph>
                <CodeBlock language={codes['issue-token-other-option'].type}>
                    {codes['issue-token-other-option'].contents}
                </CodeBlock>
                
                <LinkTitle 
                    variant="h2" link="#transfer-token" 
                    text={ t("7. Transfer a token") }
                />
                    { t("The next step is regarding how to transfer some of the tokens") }
                <CodeBlock language={codes['transfer-token'].type}>
                    {codes['transfer-token'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("When you run the transfer action") }
                </Paragraph>
                <CodeBlock language={codes['transfer-token-result'].type}>
                    {codes['transfer-token-result'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("You can verify whether john received the token") }
                </Paragraph>
                <CodeBlock language={codes['transfer-get-currency-john'].type}>
                    {codes['transfer-get-currency-john'].contents}
                </CodeBlock>
                <CodeBlock language={codes['transfer-get-currency-john-result'].type}>
                    {codes['transfer-get-currency-john-result'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("You may also check lisa's balance") }
                </Paragraph>
                <CodeBlock language={codes['transfer-get-currency-lisa'].type}>
                    {codes['transfer-get-currency-lisa'].contents}
                </CodeBlock>
                <CodeBlock language={codes['transfer-get-currency-lisa-result'].type}>
                    {codes['transfer-get-currency-lisa-result'].contents}
                </CodeBlock>
            </Box>
        </Fragment>
    )
}

export default DeployIssueTransferTokensUsingSingleNode;