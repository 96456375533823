const DeployIssueTransferTokensUsingSingleNode = {
    // obtain-contract-source
    'move-contract-folder': {
        type: 'bash',
        contents: `cd CONTRACTS_DIR`
    },
    'obtain-contract-source': {
        type: 'bash',
        contents: `git clone https://github.com/EOSIO/eosio.contracts --branch v1.7.0 --single-branch`
    },
    'move-eos-contract-folder': {
        type: 'bash',
        contents: `cd eosio.contracts/contracts/eosio.token`
    },
    
    // create-account-for-contract
    'create-account-for-contract': {
        type: 'bash',
        contents: `cleos create account eosio eosio.token EOS6MRyAjQq8ud7hVNYcfnVPJqcVpscN5So8BhtHuGYqET5GDW5CV`
    },

    // compile-contract
    'compile-contract': {
        type: 'bash',
        contents: `eosio-cpp -I include -o eosio.token.wasm src/eosio.token.cpp --abigen`
    },

    // deploy-contract
    'deploy-contract': {
        type: 'bash',
        contents: `cleos contract eosio.token CONTRACTS_DIR/eosio.contracts/contracts/eosio.token --abi eosio.token.abi -p eosio.token@active`
    },
    'deploy-contract-result': {
        type: 'bash',
        contents: `Reading WASM from ...eosio.contracts/contracts/eosio.token/eosio.token.wasm...
Publishing contract...
executed transaction: a68299112725b9f2233d56e58b5392f3b37d2a4564bdf99172152c21c7dc323f  6984 bytes  6978 us
#         eosio <= eosio::setcode               {"account":"eosio.token","vmtype":0,"vmversion":0,"code":"0061736d0100000001a0011b60000060017e006002...
#         eosio <= eosio::setabi                {"account":"eosio.token","abi":"0e656f73696f3a3a6162692f312e310008076163636f756e7400010762616c616e63...
warning: transaction executed locally, but may not be confirmed by the network yet         ]`
    },

    // create-token
    'create-token': {
        type: 'bash',
        contents: `cleos push action eosio.token create '[ "lisa", "1000000000.0000 SYS"]' -p eosio.token@active`
    },
    'create-token-alternative': {
        type: 'bash',
        contents: `cleos push action eosio.token create '{"issuer":"lisa", "maximum_supply":"1000000000.0000 SYS"}' -p eosio.token@active`
    },
    'create-token-result': {
        type: 'bash',
        contents: `executed transaction: 10cfe1f7e522ed743dec39d83285963333f19d15c5d7f0c120b7db652689a997  120 bytes  1864 us
#   eosio.token <= eosio.token::create          {"issuer":"lisa","maximum_supply":"1000000000.0000 SYS"}
warning: transaction executed locally, but may not be confirmed by the network yet         ]`
    },

    // issue-token
    'issue-token': {
        type: 'bash',
        contents: `cleos push action eosio.token issue '[ "lisa", "100.0000 SYS", "memo" ]' -p lisa@active`
    },
    'issue-token-result': {
        type: 'bash',
        contents: `executed transaction: d1466bb28eb63a9328d92ddddc660461a16c405dffc500ce4a75a10aa173347a  128 bytes  205 us
#   eosio.token <= eosio.token::issue           {"to":"lisa","quantity":"100.0000 SYS","memo":"memo"}
warning: transaction executed locally, but may not be confirmed by the network yet         ]`
    },
    'issue-token-other-option': {
        type: 'bash',
        contents: `cleos push action eosio.token issue '[ "lisa", "100.0000 SYS", "memo" ]' -p lisa@active -d -j`
    },

    // transfer-token
    'transfer-token': {
        type: 'bash',
        contents: `cleos push action eosio.token transfer '[ "lisa", "john", "25.0000 SYS", "m" ]' -p lisa@active`
    },
    'transfer-token-result': {
        type: 'bash',
        contents: `executed transaction: 800835f28659d405748f4ac0ec9e327335eae579a0d8e8ef6330e78c9ee1b67c  128 bytes  1073 us
#   eosio.token <= eosio.token::transfer        {"from":"lisa","to":"john","quantity":"25.0000 SYS","memo":"m"}
#          lisa <= eosio.token::transfer        {"from":"lisa","to":"john","quantity":"25.0000 SYS","memo":"m"}
#          john <= eosio.token::transfer        {"from":"lisa","to":"john","quantity":"25.0000 SYS","memo":"m"}
warning: transaction executed locally, but may not be confirmed by the network yet         ]`
    },
    'transfer-get-currency-john': {
        type: 'bash',
        contents: `cleos get currency balance eosio.token john SYS`
    },
    'transfer-get-currency-john-result': {
        type: 'bash',
        contents: `25.0000 SYS`
    },
    'transfer-get-currency-lisa': {
        type: 'bash',
        contents: `cleos get currency balance eosio.token lisa SYS`
    },
    'transfer-get-currency-lisa-result': {
        type: 'bash',
        contents: `75.0000 SYS`
    },
}

export default DeployIssueTransferTokensUsingSingleNode;