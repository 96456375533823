import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const TextList = ({ list, type }) => {
    const listStyleType = type ?? 'disc';

    return (
        <List sx={{ listStyle: `${listStyleType} inside` }}>
            {
                list.map((text, index) => {
                    return (
                        <ListItem 
                            key={index} 
                            sx={{ 
                                display: 'list-item',
                                lineHeight: 1.5,
                                mt: index > 0 ? 0.5 : 0,
                                color: text.color ?? 'inherit'
                            }}
                        >
                            { 
                                typeof(text) !== 'object' || !text.contents 
                                    ? text : text.contents 
                            }
                        </ListItem>
                    )
                })
            }
        </List>
    );
}

export default TextList;