import { Fragment } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Title from "../../../components/pages/Title";
import LinkTitle from "../../../components/pages/LinkTitle";
import CodeBlock from "../../../components/pages/CodeBlock";
import Codes from '../../../constants/Codes';
import Paragraph from "../../../styles/components/Paragraph";

const DeployIssueTransferTokens = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.smartContact.usingMainnet.deployIssueTransferTokens' });
    const codes = Codes.SmartContract.UsingMainnet.DeployIssueTransferTokens;
    const developersUsingMainnetURL = `/smart-contract-guides/smart-contract-using-mainnet`;
    
    return (
        <Fragment>
            <Title text={ t("Issue a token on the mainnet") } />

            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#before-you-start" 
                    text={ t("Before you start the tutorial") }
                />
                <Paragraph>
                    { t("please check if the mainnet development environment is set up-1") }
                    <Link href={`${developersUsingMainnetURL}/basic-usages`}>
                        { t("please check if the mainnet development environment is set up-link") }
                    </Link>
                    { t("please check if the mainnet development environment is set up-2") }
                </Paragraph>

                <LinkTitle 
                    variant="h2" link="#create-contract" 
                    text={ t("1. Generate a token contract") }
                />
                <LinkTitle 
                    variant="h3" link="#duplicate-source" 
                    text={ t("Copy the source code") }
                />
                <Paragraph>
                    { t("Copy the eosio.token repo from GitHub-1") }
                    <Paragraph component="span" color="red">
                        { t("Copy the eosio.token repo from GitHub-2") }
                        <Link href={`${developersUsingMainnetURL}/basic-usages`}>
                            { t("Copy the eosio.token repo from GitHub-link") }
                        </Link>
                        { t("Copy the eosio.token repo from GitHub-3") }
                    </Paragraph>
                </Paragraph>
                <CodeBlock language={codes['duplicate-source'].type}>
                    {codes['duplicate-source'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#rename-files" 
                    text={ t("Change contract file name") }
                />
                <CodeBlock language={codes['rename-files'].type}>
                    {codes['rename-files'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#change-hpp" 
                    text={ t("Change hpp file name") }
                />
                <CodeBlock language={codes['change-hpp'].type}>
                    {codes['change-hpp'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#change-cpp" 
                    text={ t("Change cpp file name") }
                />
                <CodeBlock language={codes['change-cpp'].type}>
                    {codes['change-cpp'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#token-compile" 
                    text={ t("Compile a contract") }
                />
                <Paragraph>
                    { t("Run the following command") }
                </Paragraph>
                <CodeBlock language={codes['token-compile'].type}>
                    {codes['token-compile'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("If you followed all the steps correctly and did not encounter any errors") }
                </Paragraph>
                <CodeBlock language={codes['token-compile-result'].type}>
                    {codes['token-compile-result'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h2" link="#deploy-contract" 
                    text={ t("2. Deploy a contract") }
                />
                <Paragraph>
                    { t("You need to submit a transaction with two actions") }
                </Paragraph>
                <LinkTitle 
                    variant="h3" link="#setcode" 
                    text={ t("setcode") }
                />
                <Paragraph>
                    { t("\"setcode\" takes the name of the account where the smart contract") }
                </Paragraph>
                <CodeBlock language={codes['usage-setcode'].type}>
                    {codes['usage-setcode'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("In the example shown below") }
                </Paragraph>
                <CodeBlock language={codes['setcode'].type}>
                    {codes['setcode'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#setabi" 
                    text={ t("setabi") }
                />
                <Paragraph>
                    { t("\"setabi\" uses a serialized") }
                </Paragraph>
                <CodeBlock language={codes['usage-setabi'].type}>
                    {codes['usage-setabi'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#deploy" 
                    text={ t("Deploy") }
                />
                <Paragraph>
                    { t("The two actions shown above are submitted") }
                </Paragraph>
                <CodeBlock language={codes['deploy-contract'].type}>
                    {codes['deploy-contract'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h2" link="#calling-actions" 
                    text={ t("3. Call a token action") }
                />
                <LinkTitle 
                    variant="h3" link="#create-token" 
                    text={ t("Create a token") }
                />
                <Paragraph>
                    { t("The following code creates a NEWT token") }
                </Paragraph>
                <CodeBlock language={codes['create-token'].type}>
                    {codes['create-token'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#issue-token" 
                    text={ t("Issue a token") }
                />
                <Paragraph>
                    { t("The next one is the code to issue 10000.0000 NEWT") }
                </Paragraph>
                <CodeBlock language={codes['issue-token'].type}>
                    {codes['issue-token'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h3" link="#transfer-token" 
                    text={ t("Transfer a token") }
                />
                <Paragraph>
                    { t("The following code will transfer 100.0000 NEWT to the otheraccount account") }
                </Paragraph>
                <CodeBlock language={codes['transfer-token'].type}>
                    {codes['transfer-token'].contents}
                </CodeBlock>
            </Box>
        </Fragment>
    )
}

export default DeployIssueTransferTokens;