const BasicUsages = {
    // install-eosjs
    'install-eosjs-npm': {
        type: 'bash',
        contents: `npm install eosjs`
    },
    'install-eosjs-yarn': {
        type: 'bash',
        contents: `yarn add eosjs`
    },

    // basic-usage-eosjs
    'basic-usage-eosjs-commonjs': {
        type: 'javascript',
        contents: `const { Api, JsonRpc } = require('eosjs');
const { JsSignatureProvider } = require('eosjs/dist/eosjs-jssig');  // development only
const fetch = require('node-fetch'); // node only
const { TextDecoder, TextEncoder } = require('util'); // node only

const privateKeys = [privateKey1];

const signatureProvider = new JsSignatureProvider(privateKeys);
const rpc = new JsonRpc('https://bpapi.bizauto.io:8888', { fetch }); // required to read blockchain state
const api = new Api({ rpc, signatureProvider, textDecoder: new TextDecoder(), textEncoder: new TextEncoder() }); // required to submit transactions`
    },
    'basic-usage-eosjs-es-modules': {
        type: 'javascript',
        contents: `import { Api, JsonRpc } from 'eosjs';
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig';  // development only

const privateKeys = [privateKey1];

const signatureProvider = new JsSignatureProvider(privateKeys);
const rpc = new JsonRpc('https://bpapi.bizauto.io:8888'); // required to read blockchain state
const api = new Api({ rpc, signatureProvider }); // required to submit transactions`
    },

    // create-private-key-for-account
    'install-eosjs-ecc-npm': {
        type: 'bash',
        contents: `npm install eosjs-ecc`
    },
    'install-eosjs-ecc-yarn': {
        type: 'bash',
        contents: `yarn add eosjs-ecc`
    },
    'basic-usage-eosjs-ecc-commonjs': {
        type: 'bash',
        contents: `const ecc = require('eosjs-ecc');`
    },
    'basic-usage-eosjs-ecc-es-modules': {
        type: 'bash',
        contents: `import ecc from 'eosjs-ecc';`
    },
    'create-private-key': {
        type: 'javascript',
        contents: `ecc.randomKey().then(privateKey => {
    console.log('Private Key:\\t', privateKey) // wif (wallet import format)
    console.log('Public Key:\\t', ecc.privateToPublic(privateKey)) // EOSkey...
})`
    },

    // create-account-for-contract
    'newaccount': {
        type: 'javascript',
        contents: `{
    account: 'eosio',
    name: 'newaccount',
    authorization: [{
        actor: 'useraaaaaaaa',
        permission: 'active',
    }],
    data: {
        creator: 'useraaaaaaaa',
        name: 'mynewaccount',
        owner: {
            threshold: 1,
            keys: [{
                key: 'YOUR_PUBLIC_KEY',
                weight: 1
            }],
            accounts: [],
            waits: []
        },
        active: {
            threshold: 1,
            keys: [{
                key: 'YOUR_PUBLIC_KEY',
                weight: 1
            }],
            accounts: [],
            waits: []
        },
    }
}`
    },
    'buyrambytes': {
        type: 'javascript',
        contents: `{
    account: 'eosio',
    name: 'buyrambytes',
    authorization: [{
        actor: 'useraaaaaaaa',
        permission: 'active',
    }],
    data: {
        payer: 'useraaaaaaaa',
        receiver: 'mynewaccount',
        bytes: 8192,
    },
}`
    },
    'delegatebw': {
        type: 'javascript',
        contents: `{
    account: 'eosio',
    name: 'delegatebw',
    authorization: [{
        actor: 'useraaaaaaaa',
        permission: 'active',
    }],
    data: {
        from: 'useraaaaaaaa',
        receiver: 'mynewaccount',
        stake_net_quantity: '1.0000 BIZA',
        stake_cpu_quantity: '1.0000 BIZA',
        transfer: false,
    }
}`
    },
    'create-account': {
        type: 'javascript',
        contents: `(async () => {
    await api.transact({
        actions: [{
            account: 'eosio',
            name: 'newaccount',
            authorization: [{
                actor: 'useraaaaaaaa',
                permission: 'active',
            }],
            data: {
                creator: 'useraaaaaaaa',
                name: 'mynewaccount',
                owner: {
                    threshold: 1,
                    keys: [{
                        key: 'YOUR_PUBLIC_KEY',
                        weight: 1
                    }],
                    accounts: [],
                    waits: []
                },
                active: {
                    threshold: 1,
                    keys: [{
                        key: 'YOUR_PUBLIC_KEY',
                        weight: 1
                    }],
                    accounts: [],
                    waits: []
                },
            },
        },
        {
            account: 'eosio',
            name: 'buyrambytes',
            authorization: [{
                actor: 'useraaaaaaaa',
                permission: 'active',
            }],
            data: {
                payer: 'useraaaaaaaa',
                receiver: 'mynewaccount',
                bytes: 8192,
            },
        },
        {
            account: 'eosio',
            name: 'delegatebw',
            authorization: [{
                actor: 'useraaaaaaaa',
                permission: 'active',
            }],
            data: {
                from: 'useraaaaaaaa',
                receiver: 'mynewaccount',
                stake_net_quantity: '1.0000 BIZA',
                stake_cpu_quantity: '1.0000 BIZA',
                transfer: false,
            }
        }]
    }, {
        blocksBehind: 3,
        expireSeconds: 30,
    });
})();`
    },
}

export default BasicUsages;