const topNavs = [
    {
        name: '/getting-started-guides',
        content: 'gettingStartedGuides'
    },
    {
        name: '/smart-contract-guides',
        content: 'smartContractGuides'
    }
];

const leftSideNavs = [
    {
        name: 'welcome',
        content: 'welcome'
    },
    {
        name: 'getting-started-guides',
        content: 'gettingStartedGuides',
        children: [
            {
                name: 'local-development-environment',
                content: 'localDevelopmentEnvironment'
            },
            {
                name: 'hello-world-contract',
                content: 'helloWorldContract'
            },
        ]
    },
    {
        name: 'smart-contract-guides',
        content: 'smartContractGuides',
        children: [
            {
                name: 'deploy-issue-and-transfer-tokens-using-single-node',
                content: 'deployIssueAndTransferTokensUsingSingleNode'
            },
            {
                name: 'smart-contract-using-mainnet',
                content: 'basicUsages',
                children: [
                    {
                        name: 'basic-usages',
                        content: 'mainnetDevelopmentEnvironment'
                    },
                    {
                        name: 'deploy-issue-and-transfer-tokens',
                        content: 'deployIssueAndTransferTokens'
                    },
                    {
                        name: 'deploy-your-own-smart-contract',
                        content: 'deployYourOwnSmartContract'
                    },
                ]
            },
        ]
    }
];

const rightSideGithubNavs = [
    {
        name: 'github',
        url: 'https://github.com/bizamainet/bizamainet'
    },
    {
        name: 'releaseNote',
        url: 'https://github.com/bizamainet/bizamainet/releases'
    },
    {
        name: 'issue',
        url: 'https://github.com/bizamainet/bizamainet/issues'
    },
];

const rightSideNavs = {
    'welcome': [],
    'getting-started-guides': [],
    'local-development-environment': [
        {
            name: '1. Install EOSIO.CDT',
            to: '#install-eosio-cdt',
            children: [
                {
                    name: 'Mac OS X',
                    to: '#mac-os-x',
                    children: [
                        {
                            name: 'Install',
                            to: '#mac-os-x-install'
                        },
                        {
                            name: 'Uninstall',
                            to: '#mac-os-x-uninstall'
                        },
                    ]
                },
                {
                    name: 'Ubuntu (18.04)',
                    to: '#ubuntu',
                    children: [
                        {
                            name: 'Install',
                            to: '#ubuntu-install'
                        },
                        {
                            name: 'Uninstall',
                            to: '#ubuntu-uninstall'
                        },
                    ]
                },
                {
                    name: 'CentOS/Red Hat(RPM)',
                    to: '#centos-rpm',
                    children: [
                        {
                            name: 'Install',
                            to: '#centos-rpm-install'
                        },
                        {
                            name: 'Uninstall',
                            to: '#centos-rpm-uninstall'
                        },
                    ]
                },
                {
                    name: 'Install from EOSIO.CDT source code',
                    to: '#install-from-source-code',
                    children: [
                        {
                            name: 'Download',
                            to: '#download-from-source'
                        },
                        {
                            name: 'Build',
                            to: '#build-from-source'
                        },
                        {
                            name: 'Install',
                            to: '#install-from-source'
                        },
                    ]
                },
            ]
        },
        {
            name: '2. Create a wallet for development',
            to: '#create-development-wallet',
            children: [
                {
                    name: 'Create a wallet',
                    to: '#create-wallet'
                },
                {
                    name: 'Open the wallet',
                    to: '#open-wallet'
                },
                {
                    name: 'Unlocking the wallet',
                    to: '#unlock-wallet'
                },
                {
                    name: 'Import keys to a wallet',
                    to: '#import-keys-in-wallet'
                },
                {
                    name: 'Get a key for development',
                    to: '#import-development-key'
                },
            ]
        },
        {
            name: '3. Getting started with Keosd & Nodeos',
            to: '#start-keosd-nodeos',
            children: [
                {
                    name: 'Start Keosd',
                    to: '#starting-keosd'
                },
                {
                    name: 'Start Nodeos',
                    to: '#starting-nodeos'
                },
                {
                    name: 'Check endpoints of Nodeos',
                    to: '#check-nodeos-endpoints'
                },
            ]
        },
        {
            name: '4. Create a development account',
            to: '#create-development-account',
            children: [
                {
                    name: 'Create a test account',
                    to: '#create-test-account'
                },
                {
                    name: 'Verify the test account',
                    to: '#check-development-account'
                },
            ]
        },
    ],
    'hello-world-contract': [
        {
            name: '1. Create a contract',
            to: '#create-contract',
            children: [
                {
                    name: 'How to create a hello.cpp file',
                    to: '#procedure-hellocpp-file',
                },
            ]
        },
        {
            name: '2. Compile & deploy',
            to: '#compile-deploy',
            children: [
                {
                    name: 'Compile & deploy process',
                    to: '#procedure-compile-deploy'
                },
            ]
        },
        {
            name: '3. Request a smart contract action',
            to: '#calling-smart-contract-action',
            children: [
                {
                    name: 'Hi action request process',
                    to: '#procedure-calling-hi-action'
                },
            ]
        },
    ],
    'smart-contract-guides': [],
    'deploy-issue-and-transfer-tokens-using-single-node': [
        {
            name: '1. Configure the contract source code',
            to: '#obtain-contract-source',
            children: []
        },
        {
            name: '2. Create a contract account',
            to: '#create-account-for-contract',
            children: []
        },
        {
            name: '3. Compile the contract',
            to: '#compile-contract',
            children: []
        },
        {
            name: '4. Deploy the contract',
            to: '#deploy-contract',
            children: []
        },
        {
            name: '5. Create a token',
            to: '#create-token',
            children: []
        },
        {
            name: '6. Issue a token',
            to: '#issue-token',
            children: []
        },
        {
            name: '7. Transfer a token',
            to: '#transfer-token',
            children: []
        },
    ],
    'smart-contract-using-mainnet': [],
    'basic-usages': [
        {
            name: '1. Install eos.js',
            to: '#install-eosjs',
            children: [
                {
                    name: 'npm',
                    to: '#install-eosjs-npm',
                },
                {
                    name: 'yarn',
                    to: '#install-eosjs-yarn',
                },
            ]
        },
        {
            name: '2. Default eos.js setting',
            to: '#basic-usage-eosjs',
            children: [
                {
                    name: 'CommonJS',
                    to: '#basic-usage-eosjs-commonjs',
                },
                {
                    name: 'ES Modules',
                    to: '#basic-usage-eosjs-es-modules',
                },
                
            ]
        },
        {
            name: '3. Generate an account private key',
            to: '#create-private-key-for-account',
            children: [
                {
                    name: 'Install eosjs-ecc',
                    to: '#install-eosjs-ecc',
                    children: [
                        {
                            name: 'npm',
                            to: '#install-eosjs-ecc-npm',
                        },
                        {
                            name: 'yarn',
                            to: '#install-eosjs-ecc-yarn',
                        },
                    ]
                },
                {
                    name: 'Generate a private key',
                    to: '#create-private-key',
                }
            ]
        },
        {
            name: '4. Create a contract account',
            to: '#create-account-for-contract',
            children: [
                {
                    name: 'newaccount',
                    to: '#newaccount',
                },
                {
                    name: 'buyrambytes',
                    to: '#buyrambytes',
                },
                {
                    name: 'delegatebw',
                    to: '#delegatebw',
                },
                {
                    name: 'Create an account',
                    to: '#create-account',
                },
            ]
        },
        {
            name: 'What is the next step?',
            to: '#what-is-next',
            children: []
        },
    ],
    'deploy-issue-and-transfer-tokens': [
        {
            name: 'Before you start the tutorial',
            to: '#before-you-start',
            children: []
        },
        {
            name: '1. Generate a contract token',
            to: '#create-contract',
            children: [
                {
                    name: 'Copy the source code',
                    to: '#duplicate-source',
                },
                {
                    name: 'Change contract file name',
                    to: '#rename-files',
                },
                {
                    name: 'Change hpp file name',
                    to: '#change-hpp',
                },
                {
                    name: 'Change cpp file name',
                    to: '#change-cpp',
                },
                {
                    name: 'Compile a contract',
                    to: '#token-compile',
                },
            ]
        },
        {
            name: '2. Deploy a contract',
            to: '#deploy-contract',
            children: [
                {
                    name: 'setcode',
                    to: '#setcode',
                },
                {
                    name: 'setabi',
                    to: '#setabi',
                },
                {
                    name: 'Deploy',
                    to: '#deploy',
                },
            ]
        },
        {
            name: '3. Call a token action',
            to: '#calling-actions',
            children: [
                {
                    name: 'Create a token',
                    to: '#create-token',
                },
                {
                    name: 'Issue a token',
                    to: '#issue-token',
                },
                {
                    name: 'Transfer a token',
                    to: '#transfer-token',
                },
            ]
        },
    ],
    'deploy-your-own-smart-contract': [
        {
            name: 'Before you start the tutorial',
            to: '#before-you-start',
            children: []
        },
        {
            name: '1. Generate a contract',
            to: '#create-contract',
            children: [
                {
                    name: 'Generate a contract',
                    to: '#create-custom-contract',
                },
                {
                    name: 'Compile a contract',
                    to: '#compile-custom-contract',
                },
            ]
        },
        {
            name: '2. Deploy a contract',
            to: '#deploy-contract',
            children: [
                {
                    name: 'setcode',
                    to: '#setcode',
                },
                {
                    name: 'setabi',
                    to: '#setabi',
                },
                {
                    name: 'Deploy',
                    to: '#deploy',
                },
            ]
        },
        {
            name: '3. Call an action',
            to: '#calling-actions',
            children: []
        },
    ],
};

const Navs = {
    topNavs,
    leftSideNavs,
    rightSideGithubNavs,
    rightSideNavs
};

export default Navs;