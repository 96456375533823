import { Fragment } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Title from "../../components/pages/Title";
import LinkTitle from "../../components/pages/LinkTitle";
import CodeBlock from "../../components/pages/CodeBlock";
import TextList from "../../components/pages/TextList";
import Codes from '../../constants/Codes';
import Paragraph from "../../styles/components/Paragraph";

const DevelopmentEnvironment = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'pages.gettingStarted.developmentEnvironment' });
    const codes = Codes.GettingStarted.DevelopmentEnvironment;   
    
    return (
        <Fragment>
            <Title text={ t("Setting up the local development environment") } />

            {/* CDT 설치 */}
            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#install-eosio-cdt" 
                    text={ t("1. Install EOSIO.CDT") }
                />

                <Paragraph>
                    { t("The EOSIO Contract Development Toolkit(CDT) is a collection of tools related to contract compilation") }
                </Paragraph>
                <Paragraph>
                    { t("Starting with version 1.3.x") }
                </Paragraph>

                <LinkTitle 
                    variant="h3" link="#mac-os-x" 
                    text={ t("Mac OS X") }
                />
                <LinkTitle 
                    variant="h4" link="#mac-os-x-install" 
                    text={ t("Install") }
                />
                <CodeBlock language={codes['install-eos-cdt-mac-os-x'].type}>
                    {codes['install-eos-cdt-mac-os-x'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h4" link="#mac-os-x-uninstall" 
                    text={ t("Uninstall") }
                />
                <CodeBlock language={codes['uninstall-eos-cdt-mac-os-x'].type}>
                    {codes['uninstall-eos-cdt-mac-os-x'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h3" link="#ubuntu" 
                    text={ t("Ubuntu(18.04)") }
                />
                <LinkTitle 
                    variant="h4" link="#ubuntu-install" 
                    text={ t("Install") }
                />
                <CodeBlock language={codes['install-eos-cdt-ubuntu-18-04'].type}>
                    {codes['install-eos-cdt-ubuntu-18-04'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h4" link="#ubuntu-uninstall" 
                    text={ t("Uninstall") }
                />
                <CodeBlock language={codes['uninstall-eos-cdt-ubuntu-18-04'].type}>
                    {codes['uninstall-eos-cdt-ubuntu-18-04'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h3" link="#centos-rpm" 
                    text={ t("CentOS/Red Hat(RPM)") }
                />
                <LinkTitle 
                    variant="h4" link="#centos-rpm-install" 
                    text={ t("Install") }
                />
                <CodeBlock language={codes['install-eos-cdt-centos-rpm'].type}>
                    {codes['install-eos-cdt-centos-rpm'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h4" link="#centos-rpm-uninstall" 
                    text={ t("Uninstall") }
                />
                <CodeBlock language={codes['uninstall-eos-cdt-centos-rpm'].type}>
                    {codes['uninstall-eos-cdt-centos-rpm'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h3" link="#install-from-source-code" 
                    text={ t("Install from EOSIO.CDT source code") }
                />
                <Paragraph sx={{ py: 0.5 }}>
                    { t("It does not matter where you duplicate the eosio.cdt") }
                </Paragraph>
                <CodeBlock language={codes['install-from-source-move-directory'].type}>
                    {codes['install-from-source-move-directory'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h4" link="#download-from-source" 
                    text={ t("Download") }
                />
                <Paragraph sx={{ py: 0.5 }}>
                    { t("Duplicate eosio.cdt version 1.8.0") }
                </Paragraph>
                <CodeBlock language={codes['install-from-source-download'].type}>
                    {codes['install-from-source-download'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h4" link="#build-from-source" 
                    text={ t("Build") }
                />
                <CodeBlock language={codes['install-from-source-build'].type}>
                    {codes['install-from-source-build'].contents}
                </CodeBlock>
                <LinkTitle 
                    variant="h4" link="#install-from-source" 
                    text={ t("Install") }
                />
                <CodeBlock language={codes['install-from-source'].type}>
                    {codes['install-from-source'].contents}
                </CodeBlock>
            </Box>
        
            {/* Create Wallet */}
            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#create-development-wallet" 
                    text={ t("2. Create a wallet for development") }
                />
                <Paragraph>
                    { t("Private keys are stored on the local Keosd") }
                </Paragraph>
                <br/>
                <Paragraph>
                    { t("Cleos allows you to execute commands on the blockchain and interact with your accounts "
                            + "and keys through wallets and other commands") }
                </Paragraph>

                <LinkTitle 
                    variant="h3" link="#create-wallet" 
                    text={ t("Create a wallet") }
                />
                <Paragraph>
                    { t("The first step is to create a wallet") }
                </Paragraph>
                <CodeBlock language={codes['create-wallet'].type}>
                    {codes['create-wallet'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("You should save the password that cleos returns separately") }
                </Paragraph>
                <CodeBlock language={codes['create-wallet-result'].type}>
                    {codes['create-wallet-result'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h3" link="#open-wallet" 
                    text={ t("Open the wallet") }
                />
                <CodeBlock language={codes['open-wallet'].type}>
                    {codes['open-wallet'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("Run this in order to check the list of wallets") }
                </Paragraph>
                <CodeBlock language={codes['wallet-list'].type}>
                    {codes['wallet-list'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("The wallet list will be returned as follows") }
                </Paragraph>
                <CodeBlock language={codes['wallet-list-result'].type}>
                    {codes['wallet-list-result'].contents}
                </CodeBlock>

                <Box component="div" sx={{ pr: 5 }}>
                    <LinkTitle 
                        variant="h3" link="#unlock-wallet" 
                        text={ t("Unlocking the wallet") }
                    />
                    <CodeBlock language={codes['unlock-wallet'].type}>
                        {codes['unlock-wallet'].contents}
                    </CodeBlock>
                    <Paragraph>
                        { t("The keosd wallet has been opened") }
                    </Paragraph>
                    <CodeBlock language={codes['wallet-list'].type}>
                        {codes['wallet-list'].contents}
                    </CodeBlock>

                    <Paragraph>
                        { t("Unlocked wallets are marked with an asterisk (*) in the wallet list") }
                    </Paragraph>
                    <CodeBlock language={codes['unlock-wallet-list-result'].type}>
                        {codes['unlock-wallet-list-result'].contents}
                    </CodeBlock>
                </Box>

                <Box component="div" sx={{ pr: 5 }}>
                    <LinkTitle 
                        variant="h3" link="#import-keys-in-wallet" 
                        text={ t("Import Keys to a Wallet")}
                    />
                    <Paragraph>
                        { t("Run this in order to generate a private key using cleos") }
                    </Paragraph>
                    <CodeBlock language={codes['create-key'].type}>
                        {codes['create-key'].contents}
                    </CodeBlock>
                    <Paragraph>
                        { t("It will be returned as follows") }
                    </Paragraph>
                    <CodeBlock language={codes['create-key-result'].type}>
                        {codes['create-key-result'].contents}
                    </CodeBlock>
                </Box>

                <Box component="div" sx={{ pr: 5 }}>
                    <LinkTitle 
                        variant="h3" link="#import-development-key" 
                        text={ t("Get a key for development") }
                    />
                    <Paragraph>
                        { t("Every new chain comes with a development key and it is the same for all the cases") }
                    </Paragraph>
                    <CodeBlock language={codes['import-key'].type}>
                        {codes['import-key'].contents}
                    </CodeBlock>
                    <Paragraph>
                        { t("When you get a message to enter your private key") }
                    </Paragraph>
                    <CodeBlock language={codes['import-key-result'].type}>
                        {codes['import-key-result'].contents}
                    </CodeBlock>
                    <Paragraph variant="caption" sx={{ color: 'red', float: 'right', mt: -1.5 }}>
                        { t("Do not use a development key for the production environment account") }
                    </Paragraph>
                </Box>
            </Box>

            {/* Keosd & Nodeos */}
            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#start-keosd-nodeos" 
                    text={ t("3. Getting started with Keosd & Nodeos") }
                    sx={{ mt: 5 }}
                />
                <LinkTitle 
                    variant="h3" link="#starting-keosd" 
                    text={ t("Start Keosd") }
                />
                <CodeBlock language={codes['start-keosd'].type}>
                    {codes['start-keosd'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("Running the command shown above will bring results such as") }
                </Paragraph>
                <CodeBlock language={codes['start-keosd-result'].type}>
                    {codes['start-keosd-result'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h3" link="#starting-nodeos" 
                    text={ t("Start Nodeos") }
                />
                <CodeBlock language={codes['start-nodeos'].type}>
                    {codes['start-nodeos'].contents}
                </CodeBlock>
                <Box>
                    <Paragraph sx={{ mb: 1 }}>
                        { t("These settings will execute the following actions") }
                    </Paragraph>
                    <TextList
                        type="decimal"
                        list={[
                            t("Run Nodeos").trim(),
                            t("Set CORS to be unrestricted(*) and enable development logging"),
                        ]}
                    />
                </Box>

                <LinkTitle 
                    variant="h4" link="#check-nodeos-endpoints" 
                    text={ t("Check endpoints of Nodeos") }
                />
                <Box>
                    <Paragraph sx={{ mb: 1 }}>
                        { t("Verify the RPC API is working correctly") }
                    </Paragraph>
                    <TextList
                        type="decimal"
                        list={[
                            t("Copy chain get_info url").trim(),
                            t("Verify in your system console using the same method shown above"),
                        ]}
                    />
                </Box>
                <CodeBlock language={codes['check-nodeos'].type}>
                    {codes['check-nodeos'].contents}
                </CodeBlock>
            </Box>

            {/* Create Account */}
            <Box component="div">
                <LinkTitle 
                    variant="h2" link="#create-development-account" 
                    text={ t("4. Create a development account") }
                />
                <Paragraph sx={{ mb: 1 }}>
                    { t("An account is a set of permissions and is stored on the blockchain to identify individual users") }
                </Paragraph>
                <Paragraph sx={{ mb: 1 }}>
                    { t("Accounts have a flexible permission structure") }
                </Paragraph>
                <Paragraph>
                    { t("This guide uses two \"user\" accounts") }
                </Paragraph>

                <LinkTitle 
                    variant="h3" link="#create-test-account" 
                    text={ t("Create a test account") }
                />
                <CodeBlock language={codes['create-test-account'].type}>
                    {codes['create-test-account'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("A message confirming the transaction has been broadcasted will be shown") }
                </Paragraph>
                <CodeBlock language={codes['create-test-account-result'].type}>
                    {codes['create-test-account-result'].contents}
                </CodeBlock>

                <LinkTitle 
                    variant="h4" link="#check-development-account" 
                    text={ t("Verify the test account") }
                />
                <CodeBlock language={codes['check-test-account'].type}>
                    {codes['check-test-account'].contents}
                </CodeBlock>
                <Paragraph>
                    { t("You can use the cleos get account command") }
                </Paragraph>
                <CodeBlock language={codes['check-test-account-result'].type}>
                    {codes['check-test-account-result'].contents}
                </CodeBlock>
            </Box>
        </Fragment>
    )
}

export default DevelopmentEnvironment;